<nz-select [style.width]="width" nzShowSearch [nzAllowClear]="this.clearable" (nzScrollToBottom)="loadMore()" nzPlaceHolder="{{'SELECT_A_USER' | translate }}"
[nzCustomTemplate]="optionsTemplate" [nzNotFoundContent]="noUserFoundTmpl" [ngModel]="selectedUserId" (ngModelChange)="userChanged($event)"
(nzOnSearch)="filterUser($event)" nzServerSearch [nzDisabled]="disabled" [nzMode]="multiple ? 'multiple' : 'default'" [nzStatus]="nzStatus">
  <nz-option *ngFor="let user of usersOptions$|async" [nzValue]="user?.id" [nzLabel]="user?.name"></nz-option>
  [class]="multiple ? 'multiple-selection' : 'single-selection'"
</nz-select>
<ng-template #optionsTemplate let-selected>
  <div class="ant-select-selection-item-content">
      <j-user [user]="getUser(selected.nzValue)" [size]="18"></j-user>
  </div>
</ng-template>
<ng-template #noUserFoundTmpl>
    No user found.
</ng-template>

