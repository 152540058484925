import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { AppointmenteStore, AppointmentState } from './appointment.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'createdAt',
  sortByOrder: Order.ASC
})
export class AppointmentQuery extends QueryEntity<AppointmentState> {
  constructor(protected store: AppointmenteStore) {
    super(store);
  }

  getAppointmentsByIssueId(issueId: string) {
    return this.getAll({
      filterBy: appointment => appointment.issueId === issueId
    })
  }

  getAppointmentsByUserId(userId: string) {
    return this.getAll({
      filterBy: appointment => appointment.userId === userId
    })
  }
}
