import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'j-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {

  @Input() control: FormControl;
  @Input() disabled: boolean = false;
  @Input() object: Object;
  @Input() label;
  @Input() hidden: boolean = false;
  @Input() width: string;
  @Input() ordered: boolean = false;
  selectOptions: any;

  constructor() { }

  get _width() {
    if (this.width) {
      return { width: this.width }
    }
  }

  private setSelectOptions() {
    this.selectOptions = Object.keys(this.object).map((option: any) => {
      if (isNaN(option)) {
        const labelValue = option.replace(/_/g, ' ');
        return ({ label: labelValue, value: this.object[option] })
      } else {
        return ({ label: this.object[option].replace(/_/g, ' '), value: option })
      }
    });
    if (this.ordered) {
      this.selectOptions.sort((a, b) => a.label - b.label);
    }
  }

  ngOnInit(): void {
    this.setSelectOptions();
    this.control = this.control ?? new FormControl('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSelectOptions();
  }
}
