import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IUser } from '@common/interfaces/user';
import { ID } from '@common/interfaces/id';
import { BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import { switchMap, } from 'rxjs/operators';
import { logger } from '@ep-om/utils/logger';
import { ObjectHelpers } from '@common/utils/object.helpers';

@Component({
  selector: 'user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSelectComponent implements OnInit, OnChanges {

  @Input() multiple: boolean = false;
  @Input() width: string = "100%";
  @Input() disabled: boolean;
  @Input() users: IUser[];
  private users$ = new BehaviorSubject<IUser[]>(null);
  @Input() pageSize: number = 10;
  @Input() selectedUserId: string | string[] = null;
  @Input() clearable: boolean = true;
  @Input() nzStatus: string = '';
  selectedUserId$ = new BehaviorSubject<ID | ID[]>(null);
  @Output() onUserChanged = new EventEmitter<IUser | IUser[]>();
  usersOptions$: Observable<IUser[]>;
  userFilter$ = new BehaviorSubject<string>('');
  page$ = new BehaviorSubject<number>(0);

  constructor() { }

  filterUser(value: string) {
    this.userFilter$.next(value);
  }

  userChanged(id: ID | ID[]) {
    this.selectedUserId$.next(this.selectedUserId);
    this.onUserChanged.emit(this.getUser(id));
  }

  loadMore() {
    this.page$.next(this.page$.value + this.pageSize);

  }

  ngOnChanges(changes: SimpleChanges) {
    const selectedUserChange = changes.selectedUserId;
    const usersChanges = changes.users;
    if (selectedUserChange) {
      if (
        (Array.isArray(selectedUserChange.currentValue) && !(ObjectHelpers.arrEquals(selectedUserChange.currentValue, selectedUserChange.previousValue)))
        ||
        (!Array.isArray(selectedUserChange.currentValue) && selectedUserChange.currentValue !== selectedUserChange.previousValue)
      ) {
        this.selectedUserId$.next(selectedUserChange.currentValue);
      }
    }
    if (usersChanges) {
      this.users$.next(usersChanges.currentValue);
    }
  }

  ngOnInit(): void {
    this.selectedUserId$.next(this.selectedUserId);
    //this.userId = this.selectedUserId;
    this.usersOptions$ = combineLatest([
      this.users$,
      this.userFilter$,
      this.page$,
      this.selectedUserId$,
    ]).pipe(
      switchMap(([users, filter, page, selectedUserId]) => {
        let options = [];
        if (!filter) {
          options = users?.slice(0, this.pageSize + page) || [];
        } else {
          options = users?.filter(u => u.name.match(new RegExp(`${filter}`, 'ig'))).slice(0, this.pageSize + page) || [];
        }
        if (selectedUserId?.length > 0) {
          if (Array.isArray(selectedUserId)) {
            for (const userId of selectedUserId) {
              if (!options.find(o => o.id === userId)) {
                options.push(this.getUser(userId));
              }
            }
          } else if (!options.find(o => o.id === selectedUserId)) {
            options.push(this.getUser(selectedUserId));
          }
        }
        return of(options);
      }),
    )
  }

  getUser(userId: string | string[]): any {
    if (Array.isArray(userId)) {
      return this.users?.filter((user) => userId.some(u => u === user.id));
    } else {
      return this.users?.find(user => user.id === userId);
    }
  }
}
