import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {

  @Input() formControl;
  @Input() readonly;
  toggle: boolean = false;
  color = ''

  changeColor(event: string) {
    this.formControl.setValue(event);
    this.formControl.markAsDirty();
  }

}
