import { Injectable } from "@angular/core";
import { Comments } from "@common/interfaces/comment";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface CommentState extends EntityState<Comments, ID> { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_COMMENTS })
export class CommentStore extends EntityStore<CommentState> {
    constructor() {
        super([]);
    }
}
