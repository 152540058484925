import { Injectable } from '@angular/core';
import { IIDolApparatoStore } from './iidOlApparato.store';
import { IID_OL_APPARATO } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolApparatoQuery } from './iidOlApparato.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolApparatoService extends CrudService<IID_OL_APPARATO, IIDolApparatoStore, IIDolApparatoQuery> {
  constructor(
    protected store: IIDolApparatoStore,
    protected query: IIDolApparatoQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_OL_APPARATO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
