import { IAppointment } from "@common/interfaces/appointment";
import { IBaseInterfaceData } from "@common/interfaces/base";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { getInterfaceNameInterable, InterfaceName } from "@common/interfaces/issueTypeInterface";
import { SystemRole } from "@common/interfaces/permissions";
import { IUserWithRoles } from "@common/interfaces/user";
import { CreationRules, Flow, StateRule, SuspensionFlow, WorkFlowSettings } from "@common/interfaces/workflow";
import { ObjectHelpers as OH } from '@common/utils/object.helpers';
import { IProjectScope } from "@common/interfaces/projectScope";


export type InterfaceDataContext = Record<InterfaceName, IBaseInterfaceData[]>;
export type TargetUser = 'Reporter' | 'Assignee';
export type PerformerUser = TargetUser | 'Admin' | 'Creator';

export type IIDPermission = 'editable' | 'readonly' | 'hidden';
export type InterfaceDataPermissions = Record<InterfaceName, IIDPermission>

// TODO da verificare ma dovrebbe far rifermineto a InterfaceName backend/src/common/interfaces/issueTypeInterface.ts
export const defaultInterfacePermissions: InterfaceDataPermissions = {
  0: 'editable',
  1: 'editable',
  2: 'editable',
  3: 'editable',
  4: 'editable',
  5: 'editable',
  6: 'editable',
  7: 'editable',
  8: 'editable',
  9: 'editable',
  10: 'editable',
  11: 'editable',
  12: 'editable',
  13: 'editable',
  14: 'editable',
  15: 'editable',
  16: 'editable',
  17: 'readonly',
  18: 'hidden',
  19: 'editable',
  20: 'editable',
  21: 'editable',
  22: 'hidden',
  23: 'editable',
  24: 'hidden',
  25: 'hidden',
  26: 'editable',
  27: 'editable',
  28: 'editable',
  29: 'readonly',
  30: 'readonly',
  31: 'readonly',
  32: 'readonly'
}
export interface IIssueContext {
  issue: IIssue,
  interfaceData: InterfaceDataContext,
}

export interface DeltaInterface {
  interfaceName: InterfaceName,
  delta: Partial<IBaseInterfaceData>
}

export class WorkflowDog {

  static canViewState(settings: WorkFlowSettings, user: IUserWithRoles, stateId: ID) {
    if (!settings || !settings.hiddenStatesByRole || settings.hiddenStatesByRole.length === 0) {
      return true;
    }

    const hiddenState = settings.hiddenStatesByRole.find(hiddenStates => hiddenStates.states.includes(stateId));
    if (!hiddenState) {
      return true;
    }

    if ([SystemRole.SysAdmin, SystemRole.Service, SystemRole.Viewer].includes(user.globalRoleId)) {
      return true;
    }

    return !user.roles.every(role => hiddenState.roles.includes(role));
  }

  static canCreateTask(settings: WorkFlowSettings, user: IUserWithRoles, issue: IIssueContext) {
    if (!settings || !settings.creationRules) {
      return true;
    }
    if (user.roles.includes('Service') || user.roles.includes('Viewer')) {
      return false;
    }
    return this.validateCreationRules(settings.creationRules, issue);
  }

  private static validateCreationRules(rules: CreationRules[], issue: IIssueContext) {
    if (!rules) {
      return true;
    }
    return rules.every(rule => {
      const interfaceDataArray = issue.interfaceData[rule.id];
      switch (rule.value) {
        case 'isValid': {
          return interfaceDataArray.every(elem => elem.isValid);
          break;
        }
        case 'isNotEmpty': {
          return interfaceDataArray.every(thing => !OH.hasOnlyEmptyValues(thing));
          break;
        }
        case 'isEmpty': {
          return interfaceDataArray.every(thing => OH.hasOnlyEmptyValues(thing));
        }
        case 'atLeastOne': {
          return interfaceDataArray.length > 0;
        }
      }
    })
  }

  static canChangeState(settings: WorkFlowSettings, issueContext: IIssueContext, newStateId: ID, user: IUserWithRoles, projectScope: IProjectScope): boolean {
    const cantChange = 'Can\'t change state, ';    
    if (!!issueContext.issue.suspension) {
      console.error(cantChange, 'suspension is active')
      return false;
    }

    const userIdentity = this.getUserIdentities(user, issueContext.issue);
    
    if (
      user.roles.includes('Service') || user.roles.includes('Viewer')
      || user.globalRoleId === SystemRole.Service || user.globalRoleId === SystemRole.Viewer
      || userIdentity.length === 0 ) {
        console.error(cantChange, 'user has role Service oe Viewer or identity was not found')
      return false;
    }

    if (!settings || !settings.flow || settings.flow.length === 0) {
      return true;
    }

    const issue = issueContext.issue;

    if (issue.stateId === newStateId) {
      return true;
    }

    const flows = settings.flow.filter(flow => {
      const result = (flow.from.includes(issue.stateId) && flow.to.includes(newStateId)) ||
        (flow.from.includes("*") && flow.to.includes(newStateId)) ||
        (flow.to.includes("*") && flow.from.includes(issue.stateId)) ||
        (flow.from.includes("*") && flow.to.includes("*"));
      return result;
    })

    if (flows.length === 0) {
      return false;
    }

    const result = flows.every(flow => {
      const temp = this.validateFlowConditions(flow, issueContext, projectScope)
      const temp1 = this.validateFlowInterfaces(flow, issueContext);
      return temp && temp1
    });
    return result;
  }

  static validateFlowInterfaces(flow: Flow, issueContext: IIssueContext) {
    if (!flow.interfaces) {
      return true;
    }
    return flow.interfaces.every(elem => {
      const interfaceDataArray = issueContext.interfaceData[elem.id];
      switch (elem.value) {
        case 'isEmpty':
          return interfaceDataArray.every(elem => OH.hasOnlyEmptyValues(elem));
        case 'isNotEmpty':
          return interfaceDataArray.every(thing => !OH.hasOnlyEmptyValues(thing));
        case 'isValid':
          return interfaceDataArray.every(elem => elem.isValid);
        case 'atLeastOne':
          return interfaceDataArray.length > 0;
        default:
          return true;
      }
    })
  }

  static canSuspend(issue: IIssue, settings: WorkFlowSettings) {
    if (!settings) {
      return false;
    }
    if (!settings.suspension.enabled) {
      return false;
    }
    if (!!issue.suspension) {
      return false;
    }
    if (settings.suspension.notAllowedStates.includes(issue.stateId)) {
      return false;
    }
    return true;
  }

  static getStatesFromSuspension(flow: SuspensionFlow[], stateId: ID) {
    if (!flow) return undefined;

    const specificFlow = flow.find(f => f.from.includes(stateId));
    if (specificFlow) {
      return specificFlow.to[0] === '__prev__' ? stateId : specificFlow.to[0];
    }

    const anyFlow = flow.find(f => f.from.includes('*'));
    if (!anyFlow) {
      return undefined;
    }
    return anyFlow.to[0] === '__prev__' ? stateId : specificFlow.to[0];
  }

  static validateFlowConditions(flow: Flow, issueContext: IIssueContext, projectScope: IProjectScope): boolean {
    const cantChange = 'Can\'t change state, ';
    if (flow.assignee) {
      switch (flow.assignee) {
        case '_creator':
          if (issueContext.issue.assigneeId !== issueContext.issue.createdBy)
            console.error(cantChange, `issue assigneeId: ${issueContext.issue.assigneeId} is not equal to creatorId: ${issueContext.issue.createdBy}`);
            return false;
          break;
        case 'isEmpty':
          if (issueContext.issue.assigneeId)
            console.error(cantChange, `flow assignee isEmpty and issueContext assigneeId: ${issueContext.issue.createdBy}`);
            return false;
          break;
        case 'isNotEmpty':
          if (!issueContext.issue.assigneeId)
            console.error(cantChange, `flow assignee isNotEmpty aand issueContext assigneeId is empty`);
            return false;
          break;
        default:
          const isAssigneeRoleOk = projectScope.users.find(u => u.id === issueContext.issue.assigneeId)?.roles.includes(flow.assignee);
          if (!isAssigneeRoleOk) {
            console.error(cantChange, `issue assigneeId: ${issueContext.issue.assigneeId} is not equal to flow assignee: ${flow.assignee}`)
            return false;
          }
      }
    }

    if (flow.reporter) {
      switch (flow.reporter) {
        case '_creator':
          if (issueContext.issue.reporterId !== issueContext.issue.createdBy)
            console.error(cantChange, `issue reporterId: ${issueContext.issue.reporterId} is not equal to issue.createdBy: ${issueContext.issue.createdBy}`);
            return false;
          break;
        case 'isEmpty':
          if (issueContext.issue.reporterId)
            console.error(cantChange, `flow reporter isEmpty and issue reporterId: ${issueContext.issue.reporterId}`);
            return false;
          break;
        case 'isNotEmpty':
          if (!issueContext.issue.reporterId)
            console.error(cantChange, `flow reporter isNotEmpty and issue reporterId is empty`);
            return false;
          break;
        default:
          const isReporterRoleOk = projectScope.users.find(u => u.id === issueContext.issue.reporterId)?.roles.includes(flow.reporter);
          if (!isReporterRoleOk) {
            console.error(cantChange, `issue reporterId: ${issueContext.issue.reporterId} is not equal to flow reporter: ${flow.reporter}`)
            return false;
          }
      }
    }
    return true;
  }

  private static getUserIdentities(user: IUserWithRoles, issue: IIssue): PerformerUser[] {
    const result: PerformerUser[] = [];

    //Esempio Admin
    //if(user.role === '#ADMIN#') {
    //  result.push('Admin')
    //}
    if (user.roles.includes('Admin')) {
      result.push('Admin');
    }

    if (user.id === issue.reporterId) {
      result.push('Reporter');
    }

    if (user.id === issue.assigneeId) {
      result.push('Assignee');
    }

    return result;
  }

  static canPerformUserChange(settings: WorkFlowSettings, issue: IIssue, targetUser: TargetUser, performedByUser: IUserWithRoles) {
    //If empty settings or empty ruleset, permit all changes
    if (performedByUser.globalRoleId === SystemRole.Viewer) {
      return false;
    }

    if (performedByUser.globalRoleId === SystemRole.Admin || performedByUser.globalRoleId === SystemRole.SysAdmin) {
      return true;
    }

    if (!settings || !settings.rules || settings.rules?.length === 0) return true;

    for (const performedBy of this.getUserIdentities(performedByUser, issue)) {
      const neededRule = `${performedBy}CanChange${targetUser}` as StateRule;

      const specificRules = settings.rules.filter(rule => rule.onState.includes(issue.stateId) && rule.stateRules.includes(neededRule));

      const defaultRules = settings.rules.filter(rule => rule.onState.includes('*') && rule.stateRules.includes(neededRule));

      if (specificRules.length > 0)
        return true;

      if (defaultRules.length > 0)
        return true;
    }

    return false;
  }

  static getOnlyReadonlyInterface(): InterfaceDataPermissions {
    const effects: Partial<InterfaceDataPermissions> = {};

    for (const elem of getInterfaceNameInterable()) {
      effects[elem] = 'readonly';
    }

    return effects as InterfaceDataPermissions;
  }

  //static getInterfaceDataPermissions(settings: WorkFlowSettings, issue: IIssue, userId: ID): InterfaceDataPermissions {
  static getInterfaceDataPermissions(settings: WorkFlowSettings, issue: IIssue, user: IUserWithRoles): InterfaceDataPermissions {
    const baseEffects: Partial<InterfaceDataPermissions> = {}

    for (const elem of getInterfaceNameInterable()) {
      baseEffects[elem] = defaultInterfacePermissions[elem];
    }

    const result = baseEffects as InterfaceDataPermissions

    // se non ci sono settings permetto di vedere ed editare tutte le interfacce
    if (!settings) {
      return result;
    }

    if (Array.isArray(issue.hiddenInterfaces)) {
      for (const elem of issue.hiddenInterfaces) {
        baseEffects[elem] = 'hidden';
      }
    }

    if (Array.isArray(issue.visibleInterfaces)) {
      for (const elem of issue.visibleInterfaces) {
        baseEffects[elem] = 'editable';
      }
    }

    if (Array.isArray(issue.readonlyInterfaces)) {
      for (const elem of issue.readonlyInterfaces) {
        baseEffects[elem] = 'readonly';
      }
    }

    if (Array.isArray(issue.editableInterfaces)) {
      for (const elem of issue.editableInterfaces) {
        baseEffects[elem] = 'editable';
      }
    }
    //TODO if rules on interfaces are added 
    //const rules = settings.rules.filter(rule => rule.onState.includes(issue.stateId) || rule.onState.includes('*'));
    //DO STUFF

    const performer = this.getUserIdentities(user, issue);
    /// Applico le restrizioni per utenti non assegnatari o reporter
    //const notAllowedUser = !(issue.reporterId === user.id || issue.assigneeId === user.id);
    const notAllowedUser = user.globalRoleId === SystemRole.Service || !performer.some(p => ['Assignee', 'Reporter', 'Admin'].includes(p));
    if (notAllowedUser) {
      for (const elem of getInterfaceNameInterable()) {
        if (result[elem] === 'editable')
          result[elem] = 'readonly'
      }
    }

    return result;

  }

  static getAutomationDeltaForTransfer(settings: WorkFlowSettings, issue: IIssue, newStateId: ID, projectScope: IProjectScope):
    {
    deltaIssue: Partial<IIssue>,
    deltaInterfaces: DeltaInterface[]
    }
  {
    let deltaIssue: Partial<IIssue> = {};
    let deltaInterfaces:  DeltaInterface[] = []

    if (settings?.automations?.length > 0) {
      return WorkflowDog.getAutomationDelta(settings, issue, newStateId, projectScope);
    }

    deltaIssue['visibleInterfaces'] = null;
    deltaIssue['readonlyInterfaces'] = null;
    deltaIssue['hiddenInterfaces'] = null;
    deltaIssue['editableInterfaces'] = null;

    return {
      deltaIssue,
      deltaInterfaces
    }
  }

  static getAutomationDelta(settings: WorkFlowSettings, issue: IIssue, newStateId: ID, projectScope: IProjectScope, appointments?: IAppointment[]): {
    deltaIssue: Partial<IIssue>,
    deltaInterfaces: DeltaInterface[]
    }
  {
    const deltaIssue: Partial<IIssue> = {};
    const deltaInterfaces: DeltaInterface[] = [];

    if (!settings || !settings.automations) {
      return {
        deltaIssue,
        deltaInterfaces
      };
    }

    const automation = settings.automations.find(value => value.onState === newStateId);

    if (!automation)
      return {
        deltaIssue,
        deltaInterfaces
      }

    if (automation.assignee) {
      switch (automation.assignee) {
        case automation.assignee.match(/^#RESOURCE/)?.input:
          if(appointments) {
            const appointment = appointments.find(a => a.entityName === automation.assignee.split('|')[1])
            if (appointment?.userId && issue.assigneeId !== appointment.userId) {
              deltaIssue.assigneeId = appointment.userId
            }
          }
          break;
        case automation.assignee.match(/^#MANAGER_RESOURCE/)?.input:
          if(appointments) {
            const appointment = appointments.find(a => a.entityName === automation.assignee.split('|')[1])
            if(appointment?.managerId && issue.assigneeId !== appointment?.managerId) {
              deltaIssue.assigneeId = appointment.managerId;
            }
          }
          break;
        case '_creator':
          if (issue.createdBy && issue.assigneeId !== issue.createdBy) {
            deltaIssue.assigneeId = issue.createdBy
          }
          break;
        case '_reporter':
          if (issue.reporterId && issue.assigneeId !== issue.reporterId) {
            deltaIssue.assigneeId = issue.reporterId;
          }
          break;
        default:
          const assigneeId = projectScope?.users.find(u => u.roles.includes(automation.assignee))?.id;
          deltaIssue.assigneeId = assigneeId;
      }
    }

    if (automation.reporter) {
      switch (automation.reporter) {
        case automation.reporter.match(/^#RESOURCE/)?.input:
          if(appointments) {
            const appointment = appointments.find(a => a.entityName === automation.reporter.split('|')[1])
            if (appointment?.userId && issue.assigneeId !== appointment.userId) {
              deltaIssue.reporterId = appointment.userId
            }
          }
          break;
        case automation.reporter.match(/^#MANAGER_RESOURCE/)?.input:
          if(appointments) {
            const appointment = appointments.find(a => a.entityName === automation.reporter.split('|')[1])
            if(appointment?.managerId && issue.reporterId !== appointment?.managerId) {
              deltaIssue.reporterId = appointment?.managerId;
            }
          }
          break;
        case '_creator':
          if (issue.createdBy && issue.reporterId !== issue.createdBy) {
            deltaIssue.reporterId = issue.createdBy;
          }
          break;
        case '_assignee':
          if (issue.reporterId && issue.assigneeId !== issue.reporterId) {
            deltaIssue.reporterId = issue.assigneeId;
          }
          break;
        default:
          const reporterId = projectScope?.users.find(u => u.roles.includes(automation.reporter))?.id;
          deltaIssue.reporterId = reporterId;
      }
    }

    if (automation.interfaces) {
      let readonlyInterfaces = issue.readonlyInterfaces ? issue.readonlyInterfaces.slice() : [];
      let hiddenInterfaces = issue.hiddenInterfaces ? issue.hiddenInterfaces.slice() : [];
      let visibleInterfaces = issue.visibleInterfaces ? issue.visibleInterfaces.slice() : [];
      let editableInterfaces = issue.editableInterfaces ? issue.editableInterfaces.slice() : [];
      for (const elem of automation.interfaces) {
        const interfaceId = parseInt(elem.id); //interfaceName
        switch (elem.value) {
          case 'hidden':
            hiddenInterfaces.push(interfaceId);
            readonlyInterfaces = readonlyInterfaces.filter(el => el !== interfaceId);
            visibleInterfaces = visibleInterfaces.filter(el => el !== interfaceId);
            editableInterfaces = editableInterfaces.filter(el => el !== interfaceId);
            break;
          case 'readonly':
            readonlyInterfaces.push(parseInt(elem.id));
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            editableInterfaces = editableInterfaces.filter(el => el !== interfaceId);
            break;
          case 'editable':
            editableInterfaces.push(interfaceId);
            readonlyInterfaces = readonlyInterfaces.filter(el => el !== interfaceId);
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            break;
          case 'visible':
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            visibleInterfaces.push(interfaceId);
            break;
          case 'not_valid':
            deltaInterfaces.push({
              interfaceName: interfaceId,
              delta: {
                issueId: issue.id,
                isValid: false,
              }
            })
        }
      }
      deltaIssue.hiddenInterfaces = [...new Set(hiddenInterfaces)];
      deltaIssue.readonlyInterfaces = [...new Set(readonlyInterfaces)];
      deltaIssue.visibleInterfaces = [...new Set(visibleInterfaces)];
      deltaIssue.editableInterfaces = [...new Set(editableInterfaces)];
    }
    return {
      deltaIssue,
      deltaInterfaces
    }
  }

  static getInterfacesToCheck(settings: WorkFlowSettings): number[] {
    const interfaceIds = settings.flow.reduce((acc, elem) => {
      if (elem.interfaces?.length > 0) {
        elem.interfaces.forEach(el => {
          acc.add(parseInt(el.id));
        });
      }
      return acc;
    }, new Set<number>());
    return [...interfaceIds];
  }

}
