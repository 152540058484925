import { Injectable, Injector } from '@angular/core';
import { IAppointment } from '@common/interfaces/appointment';
import { PartialUpdate } from '@common/interfaces/base';
import { ID } from '@common/interfaces/id';
import { IIssue } from '@common/interfaces/issue';
import { IProject } from '@common/interfaces/project';
import { ITag } from '@common/interfaces/tag';
import { Topics } from '@common/interfaces/topics';
import { DeltaInterface, WorkflowDog } from '@common/utils/workflowDog';
import { AppService } from '@ep-om/app.service';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { AuthQuery } from '@ep-om/project/auth/auth.query';
import { compareDateString } from '@ep-om/utils/date';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { AppointmentQuery } from '../appointment/appointment.query';
import { CrudService } from '../crudService';
import { EntityInteractionQuery } from '../entityInteraction/entityInteraction.query';
import { interfaceDataQueryMap, interfaceDataServiceMap } from '../interfaceDataMapping';
import { LastUpdateQuery } from '../lastUpdate/lastUpdate.query';
import { LastUpdateService } from '../lastUpdate/lastUpdate.service';
import { ProjectQuery } from '../project/project.query';
import { ProjectService } from '../project/project.service';
import { TagService } from '../tag/tag.service';
import { IssueStoreStrategy } from '../updateStoreStrategies';
import { IssueQuery } from './issue.query';
import { IssueStore, IssueUI } from './issue.store';
import { WorkflowQuery } from '../workflow/workflow.query';
import { ProjectScopeQuery } from '../projectScope/projectScope.query';

@Injectable({
  providedIn: 'root',
})
export class IssueService extends CrudService<IIssue, IssueStore, IssueQuery> {
  protected topic = Topics.ISSUES;
  newIssueModalIsOpened$: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    protected store: IssueStore,
    public query: IssueQuery,
    protected projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private appService: AppService,
    protected lastUpdateService: LastUpdateService,
    protected lastUpdateQuery: LastUpdateQuery,
    protected socketIoService: SocketIoService,
    protected actionService: ActionService,
    protected issueStrategy: IssueStoreStrategy,
    protected entityInteractionQuery: EntityInteractionQuery,
    protected authQuery: AuthQuery,
    private tagService: TagService,
    private injector: Injector,
    private appointmentQuery: AppointmentQuery,
    private wfQuery: WorkflowQuery,
    private projectScopeQuery: ProjectScopeQuery
  ) {
    super(
      Topics.ISSUES,
      store,
      query,
      actionService,
      issueStrategy,
    );   
  }

  firstSync$ = this.updateStoreStrategy.firstSync$;

  setActive(id: ID) {
    this.store.setActive(id);
  }

  localRemoveByIssueAndProject(issue: ID, project: ID) {
    this.store.remove(({ projectId, id }) => projectId === project && id === issue )
  }

  localRemoveNotMineByProject(project: ID, user: ID) {
    this.store.remove(({projectId, createdBy, assigneeId, reporterId, notificationSubscribers}) => projectId === project && (assigneeId !== user && reporterId !== user && createdBy !== user && !notificationSubscribers.includes(user)));
  }

  getIssuesByActiveProject(): IIssue[] {
    const id = this.projectService.getActive().id;
    return this.query.getIssueByProject(String(id));
  }

  getIssueByProjectIdAndState(projectId: ID, stateId: any) {
    return this.query.getIssueByProject(projectId).filter(issue => issue.stateId === stateId);
  }

  create(issue: IIssue) {
    const workflowSettings = this.projectQuery.getActiveWorkflowSettings();
    const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === issue.projectId);
    const {deltaIssue, deltaInterfaces} = WorkflowDog.getAutomationDelta(workflowSettings, issue as IIssue, issue.stateId, projectScope)
    issue = { ...issue, ...deltaIssue }
    super.create(issue);
  }

  update(issue: PartialUpdate<IIssue>) {
    const previousIssue = this.query.getEntity(issue.id);
    if (previousIssue?.stateId !== issue.stateId && !previousIssue.id.includes('new')) {
      const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === (issue.projectId || previousIssue.projectId));;
      const workflowSettings = this.projectQuery.getWorkflowSettingsByProjectId(issue.projectId || previousIssue.projectId);
      let appointments: IAppointment[] = this.appointmentQuery.getAppointmentsByIssueId(issue.id);
      const {deltaIssue, deltaInterfaces} = WorkflowDog.getAutomationDelta(workflowSettings, issue as IIssue, issue.stateId, projectScope, appointments);
      issue = { ...issue, ...deltaIssue }
      this.applyAutomationsToInterfaces(issue.id, deltaInterfaces);
    }
    console.log('super update', issue);
    super.update(issue);
  }

  transferIssue(issue: PartialUpdate<IIssue>) {
    const previousIssue = this.query.getEntity(issue.id);
    const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === issue.projectId);
    const workflowSettings = this.projectQuery.getWorkflowSettingsByProjectId(issue.projectId || previousIssue.projectId);
    const {deltaIssue, deltaInterfaces} = WorkflowDog.getAutomationDeltaForTransfer(workflowSettings, issue as IIssue, issue.stateId, projectScope);
    issue = { ...issue, ...deltaIssue};
    super.update(issue);
  }

  addNotificationSubscriber(issueId: string): void {
    const issue = this.query.getEntity(issueId);
    const userId = this.authQuery.getLoggedUserId();
    const subcribedUsers = new Array(...issue.notificationSubscribers, userId);
    super.update({ id: issue.id, notificationSubscribers: subcribedUsers });
  }

  removeNotificationSubscriber(issueId: string) {
    const issue = this.query.getEntity(issueId);
    const userId = this.authQuery.getLoggedUserId();
    const filtredId = issue.notificationSubscribers.filter(id => id !== userId);
    super.update({ id: issue.id, notificationSubscribers: filtredId });
  }

  getPendingStatusById$(id: ID): Observable<string> {
    return this.query.ui.selectEntity(id).pipe(
      filter(issue => !!issue),
      map(({ pendingState }) => {
        if (pendingState === 'pending' || this.appService.isOnline === false) {
          return 'processing'
        } else {
          return 'success';
        }
      })
    );
  }

  updateIssueUI(issueUI: Partial<IssueUI>) {
    this.store.ui.update(issueUI.id, issueUI);
  }

  updateManyIssueUi(issueUi: IssueUI[]) {
    this.store.ui.upsertMany(issueUi);
  }

  /**
   * Check everytime you change it if the method logic is the same in updateStoreStrategy
   */
  updateIssueUILastUpdate(issueId: ID, updateAt: string, updatedBy: ID) {
    const ui = this.getUI(issueId);
    //TODO l'update dell'ui della issue derivante dallo store di entità collegate alla issue dovrebbe essere scatenato dopo l'avvenuta prima sincronizzazione delle issue - è un problema che si verifica solo al primo login
    if (!ui) {
      //logger.log(`Detected UI on issue not present ${issueId}`)
      return;
    }
    if (!ui.lastUpdate || compareDateString(updateAt, ui.lastUpdate)) {
      const userId = this.authQuery.getValue().userId;
      const news = updatedBy === userId ? false : this.entityInteractionQuery.getByIssueId(issueId).reduce((acc, curr) => {
        if (curr.userId !== userId) return acc;
        return compareDateString(updateAt, curr.updatedAt);
      }, true);
      this.store.ui.update(issueId, e => { return { ...e, lastUpdate: updateAt, performedBy: updatedBy, news } });
    }
  }

  canSuspend(issue: IIssue, project: IProject) {
    return WorkflowDog.canSuspend(issue, this.wfQuery.getEntity(project?.workflowId)?.settings);
  }

  suspend(issue: PartialUpdate<IIssue>, label: string) {
    this.update({
      ...issue,
      suspension: label,
    });
  }

  desuspend(issue: PartialUpdate<IIssue>) {
    this.update({
      ...issue,
      suspension: null,
    });
  }

  getUI(issueId: ID): IssueUI {
    return this.query.ui.getEntity(issueId);
  }

  getDayOffset(createdAt: string): number {
    const creationDate: Date = new Date(createdAt);
    const currentDate: Date = new Date();
    const utcCreationDate = Date.UTC(creationDate.getFullYear(), creationDate.getMonth(), creationDate.getDate());
    const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const differenceDays = Math.floor((Math.abs(utcCreationDate - utcCurrentDate)) / (1000 * 60 * 60 * 24));
    return differenceDays;
  }

  trash(id: ID) {
    const tags = this.query.getEntity(id)?.tagIds || [];
    const issueWithTags = this.query.getAll({
      filterBy: issue => !issue.trashedAt && issue.tagIds && issue.id !== id && issue.tagIds.some(tag => tags.includes(tag))
    });
    const tagsToRemove = tags.filter(id => !issueWithTags.some(issue => issue.tagIds.includes(id)));
    if(tagsToRemove.length > 0) {
      tagsToRemove.forEach(tagId => this.tagService.trash(tagId));
    }
    this.update({ id, trashedAt: (new Date()).toISOString() } as PartialUpdate<IIssue & { tags?: ITag[] }>);
  }

  restoreFromTrash(id: ID) {
    this.update({ id, trashedAt: null } as PartialUpdate<IIssue>);
  }

  addTag(tag: ITag | string, issue: PartialUpdate<IIssue>) {
    const issueIsNew = issue.id.startsWith('new_');
    if (typeof tag === 'string') {
      tag = {
        id: `${issueIsNew ? 'new_': ''}${tag.toLowerCase()}_${issue.projectId}`,
        projectId: `${issueIsNew ? null : issue.projectId}`,
        label: tag
      } as ITag;
      const existingTag = this.tagService.query.getEntity(tag.id);
      if (!existingTag) {
        this.tagService.create(tag);
      }
    }
    if(tag.trashedAt) {
      this.tagService.restoreFromTrash(tag.id);
    }
    this.update({
      ...issue,
      tagIds: [...issue.tagIds || [], tag.id]
    });
  }

  removeTagFromIssue(tagId: ID, issue: IIssue) {
    this.update({
      id: issue.id, 
      tagIds: issue?.tagIds.filter(tag => tag.toString() !== tagId) || []
    });
    if (issue.id.startsWith('new_')) {
      this.tagService.localRemoveEntity(tagId);
    }
  }

  softRemoveAllTrashedIssues() {
    const issuesToRemove = this.query.getAllTrashed();
    if(issuesToRemove.length > 0) {
      issuesToRemove.forEach(issue => {
        this.update({ id: issue.id, deletedAt: new Date().toISOString() });
      })
    }
  }

  applyAutomationsToInterfaces(issueId: string, deltaInterfaces: DeltaInterface[]) {
    for (const mutations of deltaInterfaces) {
      const interfaceService = this.injector.get(interfaceDataServiceMap[mutations.interfaceName]);
      const interfaceQuery = this.injector.get(interfaceDataQueryMap[mutations.interfaceName]);
      const interfaces = interfaceQuery.getByIssueId(issueId);
      for (const int of interfaces) {
        interfaceService.update({id: int.id, ...mutations.delta});
      }
    }
  }
}
