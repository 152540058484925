import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthQuery } from "@ep-om/project/auth/auth.query";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(public authQuery:AuthQuery) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authState = this.authQuery.getValue();
        if (authState && authState.accessToken) {
            req = req.clone({ setHeaders: { Authorization: `Bearer ${authState.accessToken}` }});
        } 
        return next.handle(req);
    } 
    
}