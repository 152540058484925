import { Injectable } from '@angular/core';
import { IID_GENERIC_KO } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IID_Generic_KoQuery as IID_Generic_KoQuery } from './genericKo.query';
import { IID_Generic_KoStore as IID_Generic_KoStore } from './genericKo.store';
@Injectable({
  providedIn: 'root'
})
export class IID_Generic_KoService extends CrudService<IID_GENERIC_KO, IID_Generic_KoStore, IID_Generic_KoQuery> {
  constructor(
    protected store: IID_Generic_KoStore,
    protected query: IID_Generic_KoQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_GENERIC_KO,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
