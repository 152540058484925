import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import * as Sentry from '@sentry/angular';
import { NzProgressModule } from 'ng-zorro-antd/progress';

//Localizzazione Angular
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it')

/** Localizzazione ng-zorro-antd i18n **/
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { IssueDetailsDirective } from './core/directives/issue-details.directive';
import { SnowModule } from './core/snow/snow.module';
import { CheckboxFormlyComponent } from './project/formly-wrappers/checkbox-formly/checkbox-formly.component';
import { DatePickerFormlyComponent } from './project/formly-wrappers/date-picker/date-picker-formly.component';
import { FormlyColorPickerComponent } from './project/formly-wrappers/formly-color-picker/formly-color-picker.component';
import { FormlyIconSelectComponent } from './project/formly-wrappers/formly-icon-select/formly-icon-select.component';
import { FormlySelectComponent } from './project/formly-wrappers/formly-select/formly-select.component';
import { FormlyUserSelectComponent } from './project/formly-wrappers/formly-user-select/formly-user-select.component';
import { MultipleSelectComponent } from './project/formly-wrappers/multiple-select/multiple-select.component';
import { RepeatSectionTypeComponent } from './project/formly-wrappers/repeat-section/repeat-section.type';
import { RepeatComponent } from './project/formly-wrappers/repeat/repeat.component';
import { SingleComponent } from './project/formly-wrappers/single/single.component';
import { FormlySwitchComponent } from './project/formly-wrappers/switch/formly-switch.component';
import { FormlyValidators as FV } from './project/formly-wrappers/validators/formly.validators';
import { SAVER, getSaver } from './utils/saver.provider';


const antDesignIcons = AllIcons as {[key: string]: IconDefinition;};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent, IssueDetailsDirective],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NzNotificationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSpinModule,
    NzProgressModule,
    NzImageModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('service-worker.js', {
      //enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormlyModule.forRoot({ 
      extras: { lazyRender: true },
      types: [
        { name: 'user-select-formly', component: FormlyUserSelectComponent },
        { name: 'multiple-select', component: MultipleSelectComponent },
        { name: 'repeat', component: RepeatComponent },
        { name: 'checkbox-formly', component: CheckboxFormlyComponent },
        { name: 'switch', component: FormlySwitchComponent},
        { name: 'custom-checkbox', component: CheckboxFormlyComponent},
        { name: 'date', component: DatePickerFormlyComponent },
        { name: 'formly-select', component: FormlySelectComponent },
        { name: 'formly-icon-select', component: FormlyIconSelectComponent },
        { name: 'formly-color', component: FormlyColorPickerComponent },
        { name: 'repeat-section', component: RepeatSectionTypeComponent }
        
      ],
      wrappers:[
        { name: 'single', component: SingleComponent },
      ],
      validators: [
        { name: "email-validation", validation: FV.EmailValidator }, 
        { name: "domain-validation", validation: FV.DomainValidator }, 
        { name: 'fieldMatch', validation: FV.fieldMatchValidator }, 
        { name: 'workflow-states', validation: FV.workflowStatesValidator }, 
        { name: 'workflow-issueTypes', validation: FV.workflowIssueTypesValidator },
        { name: 'issueTypes-interfaces', validation: FV.issueTypesInterfacesValidator },
        { name: 'phone-validation', validation: FV.phoneValidation },
        { name: 'username-validation', validation: FV.UsernameValidator }
      ],
      validationMessages: [
        { name: "email-validation", message: FV.EmailValidatorMessage }, 
        { name: "domain-validation", message: FV.DomainValidatorMessage }, 
        { name: 'minlength', message: FV.minValidationMessage}, 
        { name: 'maxlength', message: FV.maxValidationMessage}, 
        { name: 'required', message: 'Il campo non può essere vuoto' }, 
        { name: 'unique-state', message: 'Lo stato già esiste...' }, 
        { name: 'unique-issueType', message: 'Il tipo di task già esiste...' },
        { name: 'unique-interface', message: "L'interfaccia già esiste..." },
        { name: 'phone-validation', message: "Il numero di telefono non è valido..." },
        { name: "username-validation", message: FV.UsernameValidatorMessage }, 
      ]
    }),
    FormlyNgZorroAntdModule,
    SnowModule,
    NzModalModule,
    NzAlertModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }
    },
    { provide: SAVER, useFactory: getSaver },
    /* {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    }, */
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [/* Sentry.TraceService */],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: 'SOCKET_IO_ADDRESS', useValue: `${environment.apiUrl}`},
    { provide: NZ_ICONS, useValue: icons }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /* constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  } */
}
