import { Injectable } from "@angular/core";
import { IID_OL_APPUNTAMENTO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { IssueEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolAppuntamentoQuery } from "./iidOlAppuntamento.query";
import { IIDolAppuntamentoStore } from "./iidOlAppuntamento.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAppuntamentoService extends CrudService<IID_OL_APPUNTAMENTO, IIDolAppuntamentoStore, IIDolAppuntamentoQuery> {
  constructor(
    protected store: IIDolAppuntamentoStore,
    protected query: IIDolAppuntamentoQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_OL_APPUNTAMENTO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  reassignUser(issueId: string, newUserId: string) {
    if(!issueId || !newUserId) { return; }
    const oloAppuntamento = this.query.getOneByIssueId(issueId);
    if(oloAppuntamento && oloAppuntamento.userId !== newUserId) {
      this.update({ id: oloAppuntamento.id, userId: newUserId });
    }
  }
}
