import { Topics } from "@common/interfaces/topics";
import { ProjectQuery } from './project/project.query';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LastUpdateQuery } from './lastUpdate/lastUpdate.query';
import { LastUpdateService } from './lastUpdate/lastUpdate.service';
import { EntityStoreAction, runEntityStoreAction, transaction } from '@datorama/akita';
import { IBaseEntity, IIssueEntity, IProjectEntity } from "@common/interfaces/base";
import { ID } from "@common/interfaces/id";
import { IssueService } from "./issue/issue.service";
import { Injectable } from "@angular/core";
import { IssueStore } from "./issue/issue.store";
import { IssueQuery } from "./issue/issue.query";
import { IIssue } from "@common/interfaces/issue";
import { compareDateString } from "@ep-om/utils/date";
import { AuthQuery } from "../auth/auth.query";
import { IEntityInteraction } from "@common/interfaces/entityInteraction";
import { EntityInteractionQuery } from "./entityInteraction/entityInteraction.query";
import { AuthStore } from "../auth/auth.store";
import { BehaviorSubject } from "rxjs";
import { ProjectService } from "./project/project.service";
import { SystemRole } from "@common/interfaces/permissions";
import { UserService } from "./user/user.service";
import { ProjectScopeQuery } from "./projectScope/projectScope.query";
import { AppointmentService } from "./appointment/appointment.service";
import { IProjectScope } from "@common/interfaces/projectScope";
import { IProject } from "@common/interfaces/project";
import { ObjectHelpers } from "@common/utils/object.helpers";
import { WorkflowQuery } from "./workflow/workflow.query";

export abstract class UpdateStoreStrategy {
  abstract start(topic: Topics, _store: string): void;
  abstract updateStore(...args: any[]): void;
  public firstSync$ = new BehaviorSubject({});

  @transaction()
  protected persistUpdates<T extends IBaseEntity>(updates: { topic: string, data: T[] }, storeName: string): T[] {
    const toBeDeleted = updates.data.reduce((acc, d) => { if (!!d.deletedAt) { acc.push(d.id) } return acc; }, []);
    const toBeUpserted = updates.data.filter(d => !d.deletedAt);
    if (toBeUpserted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.UpsertManyEntities, upsertManyEntities => upsertManyEntities(toBeUpserted));
    }
    if (toBeDeleted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.RemoveEntities, removeEntities => removeEntities(toBeDeleted));
    }
    return toBeUpserted;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService
  ) { super() }
  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IProjectEntity>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe((updates) => {
      this.updateStore(topic, updates)
      if (!this.firstSync$.value[topic]) {
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    })
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProjectEntity[] }) {
    this.persistUpdates(updates, topic);
    if (!this.firstSync$.value[topic]) {
      this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
    }
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem?.projectId, updates.topic, lastItem?.updatedAt);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EntityInteractionStoreStrategy extends ProjectEntityStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected issueService: IssueService,
    protected authQuery: AuthQuery,
  ) {
    super(projectQuery, lastUpdateQuery, lastUpdateService, socketIoService)
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IEntityInteraction[] }) {
    super.updateStore(topic, updates);
    const me = this.authQuery.getValue().userId;
    updates.data
      .filter(data => data.userId === me)
      .forEach(update => {
        const currentUi = this.issueService.getUI(update.issueId);
        if (compareDateString(update.updatedAt, currentUi?.lastUpdate)) {
          this.issueService.updateIssueUI({
            ...currentUi,
            news: false,
          });
        }
      });
  }
}

@Injectable({
  providedIn: 'root'
})
export class IssueStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected issueStore: IssueStore,
    protected issueQuery: IssueQuery,
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected entityInteractionQuery: EntityInteractionQuery,
    protected authQuery: AuthQuery,
  ) { super() }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IIssue>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(updates => {
      if (updates && updates.data && updates.data.length > 0) {
        this.updateStore(topic, updates);
      }
      if (!this.firstSync$.value[topic]) {
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    });
  }


  updateStore(topic: Topics, updates: { topic: Topics, data: IIssue[] }) {
    const toBeUpdated = this.persistUpdates(updates, topic);
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem.projectId, updates.topic, lastItem.updatedAt);
    for (const elem of toBeUpdated) {
      !elem.deletedAt && this.updateUILastUpdate(elem.id, elem.updatedAt, elem.lastUpdatedBy);
    }
  }
  /**
   * Check everytime you change it if the method logic is the same in IssueService
   */
  updateUILastUpdate(issueId: ID, updateAt: string, updatedBy: ID) {
    this.issueStore.ui.update(issueId, e => {
      if (!e.lastUpdate || compareDateString(updateAt, e.lastUpdate)) {
        const userId = this.authQuery.getValue().userId;
        const news = updatedBy == userId ? false : this.entityInteractionQuery.getByIssueId(issueId).reduce((acc, curr) => {
          if (curr.userId !== userId) return acc;
          return acc === true ? true : compareDateString(updateAt, curr.updatedAt);
        }, true);
        return { ...e, lastUpdate: updateAt, performedBy: updatedBy, news };
      }
      return e;
    });
  }


}
@Injectable({
  providedIn: 'root',
})
export class IssueEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected issueService: IssueService,
    protected socketIoService: SocketIoService
  ) { super() }
  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IIssueEntity>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(updates => {
      if (updates && updates.data && updates.data.length > 0) {
        this.updateStore(topic, updates);
      }
      if (!this.firstSync$.value[topic]) {
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    });
  }


  updateStore(topic: Topics, updates: { topic: Topics, data: IIssueEntity[] }) {
    const toBeUpdated = this.persistUpdates(updates, topic);
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem.projectId, updates.topic, lastItem.updatedAt);
    for (const elem of toBeUpdated as IIssueEntity[]) {
      this.issueService.updateIssueUILastUpdate(elem.issueId, elem.updatedAt, elem.lastUpdatedBy)
    }
  }
}

@Injectable({
  providedIn: 'any',
})
export class BaseEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService
  ) { super() }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => {
        const lastUpdates = this.lastUpdateQuery.getValue();
        return this.socketIoService.listenEntity<IBaseEntity>(topic, { updatedAt: lastUpdates[topic] })
      }),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(
      updates => {
        if (updates && updates.data && updates.data.length > 0) {
          this.updateStore(topic, updates);
        }
        if (!this.firstSync$.value[topic]) {
          this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
        }
      }
    )
  }
  updateStore(topic: Topics, updates: { topic: Topics, data: IBaseEntity[] }) {
    this.persistUpdates(updates, topic);
    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectStoreStrategy extends BaseEntityStoreStrategy {
  visibilityManager: VisibilityManager;
  constructor(
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected projectQuery: ProjectQuery,
    private wfQuery: WorkflowQuery,
    projectScopeQuery: ProjectScopeQuery,
    authQuery: AuthQuery,
    appointmentService: AppointmentService,
  ) {
    super(
      lastUpdateQuery,
      lastUpdateService,
      socketIoService
    );
    this.visibilityManager = new VisibilityManager(wfQuery, projectScopeQuery, authQuery, projectQuery, appointmentService, );
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProject[] }) {
    this.visibilityManager
      .calculateStartingRoles()
      .calculateStartingResourcePerRoles();

    this.persistUpdates(updates, topic);
    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);
    
    this.visibilityManager
      .calculateCurrentRoles()
      .calculateCurrentResourcePerRoles()
      .cleanUpAppointmentByUsers()
      .retrieveMissingAppointmentByUsers()
  }

}


@Injectable({
  providedIn: 'root'
})
export class ProjectScopeStoreStrategy extends BaseEntityStoreStrategy {
  visibilityManager: VisibilityManager;
  constructor(
    protected socketIoService: SocketIoService,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected projectService: ProjectService,
    protected issueService: IssueService,
    protected authStore: AuthStore,
    protected authQuery: AuthQuery,
    protected userService: UserService,
    protected projectScopeQuery: ProjectScopeQuery,
    protected wfQuery: WorkflowQuery,
    projectQuery: ProjectQuery,
    appointmentService: AppointmentService
  ) {
    super(
      lastUpdateQuery,
      lastUpdateService,
      socketIoService
    );
    this.visibilityManager = new VisibilityManager(wfQuery, projectScopeQuery, authQuery, projectQuery, appointmentService, userService, issueService, projectService)
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProjectScope[] }) {
    this.visibilityManager
      .calculateStartingRoles()
      .calculateStartingResourcePerRoles();
    this.persistUpdates(updates, topic);
    this.authStore.update(state => ({
      ...state,
      projectRole: {
        ...state.projectRole || {},
        ...updates.data.reduce((acc, projectScope) => {
          const currentUserRole = projectScope?.users?.find(user => user.id === this.authQuery.getLoggedUserId());
          if (!currentUserRole) {
            return acc;
          }
          acc[projectScope.projectId] = currentUserRole.roles;
          return acc;
        }, {})
      }
    }));
    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);
    this.visibilityManager
      .calculateCurrentRoles()
      .calculateCurrentResourcePerRoles()
      .cleanUpAppointmentByUsers()
      .retrieveMissingAppointmentByUsers()
      .manageExternalVisibility();
  }

}

@Injectable({
  providedIn: 'root',
})
export class DummyStoreStrategy extends UpdateStoreStrategy {
  start() { }
  updateStore() { }
}


class VisibilityManager {
  loggedUserId: string;
  prevRoles: { [key: string]: string[] };
  prevResourceRoles: string[];
  prevUserPerResourceRole: string[];
  currentRoles: { [key: string]: string[] };
  currentResourceRoles: string[];
  currentUserPerResourceRole: string[];


  constructor(
    private wfQuery: WorkflowQuery,
    private projectScopeQuery: ProjectScopeQuery,
    private authQuery: AuthQuery,
    private projectQuery: ProjectQuery,
    private appointmentService?: AppointmentService,
    private userService?: UserService,
    private issueService?: IssueService,
    private projectService?: ProjectService,
  ) {

  }

  calculateStartingRoles() {
    this.loggedUserId = this.authQuery.getLoggedUserId();
    this.prevRoles = this.authQuery.getProjectRoles();
    this.prevResourceRoles = this.getResourceRoles(this.prevRoles);
    return this;
  }

  calculateStartingResourcePerRoles() {
    this.prevUserPerResourceRole = this.getUserPerResourceRole(this.prevResourceRoles)
    return this;
  }

  calculateCurrentRoles() {
    this.currentRoles = this.authQuery.getProjectRoles();
    this.currentResourceRoles = this.getResourceRoles(this.currentRoles);
    return this;
  }

  calculateCurrentResourcePerRoles() {
    this.currentUserPerResourceRole = this.getUserPerResourceRole(this.currentResourceRoles);
    return this;
  }

  cleanUpAppointmentByUsers() {
    const personsToRemove = this.prevUserPerResourceRole.filter(user => !this.currentUserPerResourceRole.includes(user));
    this.appointmentService.localRemoveByUserId(personsToRemove);
    return this;
  }

  retrieveMissingAppointmentByUsers() {
    const personsToAdd = this.currentUserPerResourceRole.filter(user => !this.prevUserPerResourceRole.includes(user));
    if (personsToAdd.length > 0) {
      this.appointmentService.remoteGet({ users: personsToAdd });
    }
    return this;
  }

  manageExternalVisibility() {
    const globalRoleId = this.userService.query.getById(this.loggedUserId)?.globalRoleId;
    const shouldISee = [SystemRole.Admin, SystemRole.SysAdmin].includes(globalRoleId);
    if (!shouldISee) {
      Object.keys(this.currentRoles).forEach(project => {
        if (this.currentRoles[project].some(role => role === 'External')) {
          this.issueService.localRemoveNotMineByProject(project, this.authQuery.getLoggedUserId());
        }
      });
    }
    if (!this.prevRoles) {
      return;
    }
    Object.keys(this.prevRoles).forEach(project => {
      if (shouldISee || this.prevRoles[project].some(role => role === 'External') && (!this.currentRoles[project] || !this.currentRoles[project].some(role => role === 'External'))) {
        this.projectService.reloadProject(project);
      }
    })
  }

  private getUserPerResourceRole(roles: string[]) {
    if (!roles || roles.length === 0) {
      return [];
    }
    return [...this.projectScopeQuery.getAll().reduce((acc, curr) => {
      if (!curr.users || curr.users.length === 0) {
        return acc;
      }
      for (const user of curr.users) {
        for (const role of user.roles) {
          if (!roles.includes(role)) {
            continue;
          }
          acc.add(user.id);
        }
      }
      return acc
    }, new Set<string>())];
  }

  private getResourceRoles(roles: { [key: string]: string[] }) {
    if (ObjectHelpers.hasOnlyEmptyValues(roles)) {
      return [];
    }
    return [...new Set<string>(this.projectQuery.getAll().reduce((acc: string[], curr) => {
      if (!this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.enabled || !this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules || this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules.length === 0) {
        return acc;
      }
      for (const rule of this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules || []) {
        if (rule.managerRoles.some(role => Object.values(roles).flat().includes(role))) {
          acc.push(...rule.resourceRoles);
        }
      };
      return acc;
    }, []))];
  }
}
