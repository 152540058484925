import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { IIssueState } from "@common/interfaces/issueState";
import { combineQueries, EntityUIQuery, QueryEntity } from "@datorama/akita";
import { combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { ProjectQuery } from "../project/project.query";
import { IssuesState, IssueStore, IssuesUIState, IssueUI } from "./issue.store";
import { sha1 } from "object-hash";
@Injectable({
  providedIn: 'root'
})
export class IssueQuery extends QueryEntity<IssuesState> {

  ui: EntityUIQuery<IssuesUIState>;
  all$ = this.select()
  active$ = this.selectActive();

  actions$ = this.selectEntityAction()

  //history: EntityStateHistoryPlugin;

  constructor(protected store: IssueStore) {
    super(store);
    this.createUIQuery();
    //this.history = new EntityStateHistoryPlugin(this)
    //this.history.undo
  }

  issueNotSuspendedWithUidByProjectAndStatus$(projectId, stateId: string): Observable<(IIssue & { ui: IssueUI })[]> {
    return this.selectAll({
      filterBy: [
        issue => issue.projectId === projectId,
        issue => issue.stateId === stateId,
        issue => !issue.suspension,
        issue => !issue.trashedAt
      ]
    })
      .pipe(
        switchMap(issues => 
          combineLatest([
            of(issues),
            this.ui.selectMany(issues.map(issue => issue.id))
            .pipe(map(uis => uis.map(ui => ({lastUpdate: ui.lastUpdate, listPosition: ui.listPosition})), distinctUntilChanged((a, b) => sha1(a) === sha1(b)))) //just for the trigger based on list and lastupdate hope this is not too slow
          ])
          
        ),
        map(([issues, ui]) =>
          issues.map(issue => ({ ...issue, ui: this.ui.getEntity(issue.id) })), //hoping getEntity to be faster than this.ui.find
        ),
        shareReplay({refCount: true, bufferSize: 1}),
      )
  }

  issueSuspendedByProject$(projectId): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: [
        issue => issue.projectId === projectId,
        //issue => issue.stateId === stateId,
        issue => !!issue.suspension,
        issue => !issue.trashedAt
      ]
    }).pipe(
      shareReplay({refCount: true, bufferSize: 1}),
    );
  }

  getAllTrashed() {
    return this.getAll({
      filterBy: issue => !!issue.trashedAt && !issue.deletedAt
    })
  }

  newsById$(issueId: ID) {
    return this.ui.selectEntity(issueId).pipe(
      filter(issueUi => !!issueUi),
      map(issueUi => issueUi.news),
      distinctUntilChanged(),
    );
  }

  newsCountByProjectId$(projectId: ID): Observable<number> {
    return combineLatest([
      this.selectAll({
        filterBy: issue => issue.projectId === projectId && !issue.trashedAt
      }),
      this.ui.selectAll()
    ]).pipe(
      //debounceTime(1500),
      map(([issues, uis]) => {
        const ids = issues.map(elem => elem.id);
        return uis.filter(ui => ui.news && ids.includes(ui.id)).length;
      }))
  }

  issueById$(issueId: ID) {
    return this.selectEntity(issueId);
  }

  issueByProjectId$(projectId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt
    });
  }

  getIssueByProject(projectId: ID) {
    return this.getAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt
    });
  }

  issueDeletedByProjectId$(projectId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !!issue.trashedAt
    });
  }

  lastIssuePosition = (state: IIssueState): number => {
    return this.getAll({
      filterBy: issue => issue.stateId === state.id
    }).length;
  }

  selectByProjectIdAndStateId$(projectId: ID, stateId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt && issue.stateId === stateId && !issue.suspension
    });
  }

  selectByProjectIdAndMilestoneId$(projectId: ID, milestoneId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt && issue.milestoneId === milestoneId && !issue.suspension
    });
  }
}
