import { Injectable } from '@angular/core';
import { InterfaceName } from '@common/interfaces/issueTypeInterface';
import { SystemRole } from '@common/interfaces/permissions';
import { IProject } from '@common/interfaces/project';
import { IUser } from '@common/interfaces/user';
import { AuthService } from '@ep-om/project/auth/auth.service';
import { BehaviorSubject, combineLatest, fromEvent, merge, of } from 'rxjs';
import { delayWhen, distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { ProjectService } from '../project/project.service';
import { UiQuery } from './ui.query';
import { DEFAULT_UI_INTERFACE, InterfaceSettings, UiStore, UI_INTERFACE_EVERYTHING_FALSE } from './ui.store';
import { AppService } from '@ep-om/app.service';
import { Position } from '@ep-om/project/service/geolocation.service';

interface GlobalInterfaceSettings {
  role: SystemRole,
  interface: InterfaceSettings
}

const SETTINGS: GlobalInterfaceSettings[] = [
  {
    role: SystemRole.Limited,
    interface: {
      defaultView: 'issue',
      projectMenu: {
        ...UI_INTERFACE_EVERYTHING_FALSE.projectMenu,
        features: {
          ...UI_INTERFACE_EVERYTHING_FALSE.projectMenu.features,
          issue: true,
        }
      },
      issueMenu: {
        ...UI_INTERFACE_EVERYTHING_FALSE.issueMenu,
        items: {
          ...UI_INTERFACE_EVERYTHING_FALSE.issueMenu.items,
          map: true,
          list: true,
        }
      },
      milestoneMenu: {
        ...UI_INTERFACE_EVERYTHING_FALSE.milestoneMenu,
      },
      milestoneModal: {
        ...UI_INTERFACE_EVERYTHING_FALSE.milestoneModal,
        details: true,
        info: true,
      },
      filterSettings: {
        ...UI_INTERFACE_EVERYTHING_FALSE.filterSettings,
        tag: true,
        issueType: true,
        issueState: true,
        milestone: true,
        priority: true,
      },
      issueModal: {
        ...UI_INTERFACE_EVERYTHING_FALSE.issueModal,
        details: true,
        description: true,
        attachments: true,
        assignee: true,
        info: true,
        comments: true,
        datesInfo: true,
      },
      issueTable: {
        ...UI_INTERFACE_EVERYTHING_FALSE.issueTable,
        title: true,
        type: true,
        state: true,
        milestone: true,
        click: true,
        priority: true,
      }
    },
  },
  {
    role: SystemRole.User,
    interface: {
      ...DEFAULT_UI_INTERFACE,
      issueModal: {
        ...DEFAULT_UI_INTERFACE.issueModal,
        delete: false,
        archive: false,
      },
    }
  }
];

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public langReady$ = new BehaviorSubject(false);

  constructor(
    private store: UiStore,
    public query: UiQuery,
    private authService: AuthService,
    private projectService: ProjectService,
    private appService: AppService,
  ) {
    combineLatest([
      of(SETTINGS),
      this.projectService.query.active$,
      this.authService.authQuery.user$.pipe(filter(user => !!user)),
      this.authService.authQuery.projectRole$,
    ]).pipe(
      map<[GlobalInterfaceSettings[], IProject, IUser, { [key: string]: string[] }], InterfaceSettings>(([globalSettings, project, user, roles]) => {
        let interfaceSettings = DEFAULT_UI_INTERFACE;
        const settings = globalSettings.find(settings => settings.role === user.globalRoleId);
        if (settings) {
          interfaceSettings = settings.interface;
        }
        interfaceSettings = {
          ...interfaceSettings,
          issueMenu: {
            defaultView: appService.FEATURES.map === 'DISABLED' ? interfaceSettings.issueMenu.defaultView : 'map',
            items: {
              ...interfaceSettings.issueMenu.items,
              map: appService.FEATURES.map === 'DISABLED' ? false : true,
            }
          }
        }
        return interfaceSettings;
      }),
    ).subscribe(settings => this.store.update({ interface: settings }));

    // fromEvent(window, 'load').pipe(delayWhen(() => this.query.isSmallScreen$.pipe(take(1)))),
    merge(
      this.authService.authQuery.loggedIn$.pipe(filter(loggedIn => !!loggedIn)),
      fromEvent(window, 'resize')
    ).pipe(
      map(() => window.innerWidth <= 700),
      filter(eventSmallScreen => eventSmallScreen != this.query.getValue().isSmallScreen),
    ).subscribe(eventSmallScreen => {
      console.log('UI: before update', this.query.getValue().isSmallScreen);
      this.store.update(state => ({ ...state, isSmallScreen: eventSmallScreen, expandedNavbar: !eventSmallScreen }));
      console.log('UI: after update', this.query.getValue().isSmallScreen);
    });
  }

  readonly NULLIFY_ROUTES_FOR = ['openEditUserModal', 'openCreateIssueModal'];

  updateOpenedInterface(active: boolean, interfaceName: InterfaceName) {
    this.store.update(state => {
      let openedInterface = [...state.openedInterface || []];
      if (active) {
        openedInterface.push(interfaceName)
      } else {
        openedInterface = openedInterface.filter(iName => iName !== interfaceName);
      }
      return {
        ...state,
        openedInterface
      }
    });
  }

  langReady(boolean: boolean) {
    this.langReady$.next(boolean);
  }

  setCurrentProjectView(view: string) {
    this.store.update({ currentProjectView: view });
  }

  setCurrentProjectSubmenuView(submenu: string, view: string) {
    this.store.update(state => ({
      currentProjectSubmenuView: {
        ...state.currentProjectSubmenuView,
        [submenu]: view
      }
    }));
  }

  setNullCurrentProjectSubmenuView() {
    this.store.update({ currentProjectSubmenuView: null });
  }

  setCurrentProject(id) {
    this.store.update({ prjId: id })
  }

  toggleNavbar() {
    this.store.update(state => ({
      ...state,
      expandedNavbar: !state.expandedNavbar
    }))
  }

  hideNavbar() {
    this.store.update({ expandedNavbar: false })
  }

  showNavbar() {
    this.store.update({ expandedNavbar: true })
  }

  updateLastPosition(position: Position) {
    this.store.update({ lastPosition: position });
  }

}
