import { Injectable } from "@angular/core";
import { IID_EP_RICHIESTA_PERMESSO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { IssueEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDEpRichiestaQuery } from "./iidEPRichiesta.query";
import { IIDEpRichiestaStore } from "./iidEPRichiesta.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDEpRichiestaService extends CrudService<IID_EP_RICHIESTA_PERMESSO, IIDEpRichiestaStore, IIDEpRichiestaQuery> {
    constructor(
      protected store: IIDEpRichiestaStore,
      protected query: IIDEpRichiestaQuery,
      protected actionService: ActionService,
      protected updateStrategy: IssueEntityStoreStrategy,
    ) {
      super(
        Topics.IID_EP_RICHIESTA_PERMESSO,
        store,
        query,
        actionService,
        updateStrategy
      );
    }
  }