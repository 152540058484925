
<div *ngIf="setBadge && isActiveSomeProject; else avatarWithoutBadge">
    <nz-badge [nzCount]="newsCount">
        <div ngClass="avatar-container" [ngStyle]="_containerStyle">
            <img *ngIf="_avatar" [src]="_avatar" [ngClass]="['avatar']" [ngStyle]="_imgStyle">
            <div *ngIf="_text" [ngStyle]="_txtStyle">{{ _text.substr(0,2).toUpperCase() }}</div>
            <svg-icon *ngIf="_svg" [name]="_svg" [size]="size"></svg-icon>
        </div>
    </nz-badge>
</div>

<ng-template #avatarWithoutBadge>
    <div [ngClass]="['avatar-container']" [ngStyle]="_containerStyle">
        <img *ngIf="_avatar" [src]="_avatar" [ngClass]="['avatar']" [ngStyle]="issueCard ? _imgStyleIssueCard : _imgStyle">
        <div *ngIf="_text" [ngStyle]="issueCard ? _txtStyleIssueCard : _txtStyle">{{ _text.substr(0,2).toUpperCase() }}</div>
        <svg-icon *ngIf="_svg" [name]="_svg" [size]="size"></svg-icon>
    </div>
</ng-template>