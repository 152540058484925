import { Component, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { combineLatest, fromEvent, interval } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WebpushNotificationService } from './core/services/wpn.service';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActionResponse } from '@common/interfaces/action';
import { AuthService } from './project/auth/auth.service';
import { snapshotManager } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { en_US, it_IT, NzI18nService } from 'ng-zorro-antd/i18n';
import { UiService } from './project/state/ui/ui.service';
import { IClientSettings } from '@common/clientSettings';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  updProgress = 0;
  letItSnow = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    public appService: AppService,
    private update: SwUpdate,
    private nzns: NzNotificationService,
    private wpns: WebpushNotificationService,
    private authService: AuthService,
    private translate: TranslateService,
    private nzI18n: NzI18nService,
    private uiService: UiService,
  ) {

    this.appService.settings$.pipe(
      filter(settings => !!settings && !!settings.i18n),
      take(1),
      tap((settings) => this.setupLanguage(settings)),
    ).subscribe();


    if (environment.production) {

      this.wpns.clicks$.subscribe(c => {
        console.log("[WP] click", c);
      })

      this.wpns.events$.subscribe(evt => {
        console.log("[WP] event", evt);
      })

      this.wpns.messages$.subscribe(msg => {
        console.log("[WP] message", msg);
      })

      this.wpns.notifications$.subscribe(nts => {
        console.log("[WP] notification", nts);
      })

    }

    const MM_DD = new Date().toISOString().substring(5, 10);
    this.letItSnow = ['12-24', '12-25', '12-26', '12-31', '01-01', '01-06'].includes(MM_DD); // nevica !

  }

  async ngOnInit() {

    await this.updManager();

    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.key === 'authStore')
    ).subscribe(event => {
      snapshotManager.setStoresSnapshot(event.newValue, { skipStorageUpdate: true });
    });

    this.activatedRoute.queryParams.subscribe(async (params) => {

      if (params.action_uid) {

        try {
          const rv: ActionResponse = await this.http.get(`${environment.apiUrl}/api/actions/execute/${params.action_uid}`).toPromise();
          if (rv.message)
            this.nzns.create('success', 'Operazione effettuata.', rv.message);
          else
            this.nzns.create('error', 'Operazione non riuscita.', rv.err);
        }
        catch (err) {
          this.nzns.create('error', 'Operazione non riuscita.', err.message);
        }

      }

    });


    // RXJS :: controllo ogni 5 secondi solo se non sono loggato 
    combineLatest([
      interval(5000),
      this.authService.authQuery.loggedIn$
    ]).pipe(
      filter(([v, li]) => !li),
      map(([v, li]) => { return v }),
    ).subscribe(async (v) => {
      await this.appService.check();
    });

    

  }

  private setupLanguage(settings: IClientSettings) {
    const languages = settings.i18n.langs;
    const mainLang = settings.i18n.mainLang;
    const defaultLang = settings.i18n.defaultLang;
    const browserLang = this.translate.getBrowserLang();
    console.log('browser lang -> ', browserLang);

    this.translate.setDefaultLang(mainLang);
    this.translate.addLangs(languages);

    if (this.translate.currentLang === 'it') {
      this.nzI18n.setLocale(it_IT);
    } else {
      this.nzI18n.setLocale(en_US);
    }

    this.translate.use(defaultLang === '_BROWSER' ? browserLang : defaultLang);
    this.uiService.langReady(true);

  }

  async updManager() {

    const s = environment.production ? 30 : 2;
    console.log('Checking updates every', s, 'seconds');
    interval(s * 1000).subscribe(() => {
      this.update.checkForUpdate()
    });

    this.update.available.subscribe(async (event) => {
      //console.log(`current`, event.current, `available`, event.available);
      if (this.appService.isVersionOk) this.nzns.create('info', `Aggiornamento applicazione`, `E' disponibile una nuova versione dell'applicazione`);
      await this.update.activateUpdate();
      setTimeout(() => { location.reload(); }, environment.production ? 5000 : 1);

      this.updProgress = 80;

      interval(250).subscribe(() => {

        this.updProgress++;

      });

    });

    this.appService.versionOk$.subscribe(value => {
      if (value) {
        this.updProgress = 0;
      } else {
        interval(500).pipe(takeUntil(this.update.available)).subscribe(() => {

          this.updProgress++;

          if (this.updProgress > 100) location.reload();

        });
      }
    })

  }
  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

}
