import { Injectable } from '@angular/core';
import { IIDepAssetsStore } from './iidEPAssets.store';
import { IID_EP_ASSET } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDepAssetsQuery } from './iidEPAssets.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDepAssetsService extends CrudService<IID_EP_ASSET, IIDepAssetsStore, IIDepAssetsQuery> {
  constructor(
    protected store: IIDepAssetsStore,
    protected query: IIDepAssetsQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      Topics.IID_EP_ASSET,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
