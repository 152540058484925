import { Injectable } from '@angular/core';
import { IIDolClienteStore } from './iidOlCliente.store';
import { IID_OL_CLIENTE } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolClienteQuery } from './iidOlCliente.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolClienteService extends CrudService<IID_OL_CLIENTE, IIDolClienteStore, IIDolClienteQuery> {
  constructor(
    protected store: IIDolClienteStore,
    protected query: IIDolClienteQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_OL_CLIENTE,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
