import { IProject } from '@common/interfaces/project';
import { EntityState, EntityStore, StoreConfig, EntityUIStore, ActiveState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { Topics } from '@common/interfaces/topics';

/* function createInitialState(): ProjectState {
  return {
    issues: [],
    users: []
  } as ProjectState;
} */

export type JProjectUI = {
  id: ID;
  newsBadge: string[];
}

export interface ProjectState extends EntityState<IProject, ID>, ActiveState { }
export interface ProjectUIState extends EntityState<JProjectUI, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.PROJECTS, resettable: true })
export class ProjectStore extends EntityStore<ProjectState> {

  ui: EntityUIStore<ProjectUIState>
  constructor() {
    super();
    //super(createInitialState());
    this.createUIStore().setInitialEntityState({ newsBadge: [] });
  }
}
