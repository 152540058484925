import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { InterfaceName } from '@common/interfaces/issueTypeInterface';
import { Store, StoreConfig } from '@datorama/akita';
import { Position } from '@ep-om/project/service/geolocation.service';

export interface IssueModalSettings {
  assignee: boolean;
  reporter: boolean;
  watcher: boolean;
  dueDate: boolean;
  datesInfo: boolean;
  priority: boolean;
  tags: boolean;
  moveTask: boolean;
  info: boolean;
  comments: boolean;
  description: boolean;
  history: boolean;
  details: boolean;
  attachments: boolean;
  olo_req_log: boolean;
  delete: boolean;
  archive: boolean;
}

export interface IssueTableSettings {
  title: boolean;
  tags: boolean;
  type: boolean;
  protocolNumber: boolean;
  state: boolean;
  milestone: boolean;
  suspension: boolean;
  reporter: boolean;
  assignee: boolean;
  priority: boolean;
  dueDate: boolean;
  updatedAt: boolean;
  createdAt: boolean;
  click: boolean;
}

export interface IssueMenu {
  defaultView: string;
  items: {
    list: boolean;
    board: boolean;
    calendar: boolean;
    map: boolean;
  }
}

export interface MilestoneMenu {
  defaultView: string;
  items: {
    list: boolean;
    board: boolean;
  }
}

export interface MilestoneModalSettings{
  details: boolean;
  description: boolean;
  startDate: boolean;
  dueDate: boolean;
  info: boolean;
}

export interface FilterMenuSettings {
  tag: boolean;
  issueType: boolean;
  issueState: boolean;
  milestone: boolean;
  reporter: boolean;
  assignee: boolean;
  priority: boolean;
}

export interface InterfaceSettings {
  defaultView: string,
  issueModal: IssueModalSettings,
  issueTable: IssueTableSettings,
  milestoneMenu: MilestoneMenu,
  issueMenu: IssueMenu,
  milestoneModal: MilestoneModalSettings,
  filterSettings: FilterMenuSettings,
  projectMenu: {
    visible: boolean;
    features: {
      issue: boolean;
      settings: boolean;
      archive: boolean;
      trash: boolean;
      external_tasks: boolean;
      export: boolean;
      milestone: boolean;
    }
  }
}

export interface UiState {
  prjId: ID;
  openedInterface: InterfaceName[];
  expandedNavbar: boolean;
  currentProjectView: string;
  currentProjectSubmenuView: { [submenu: string]: string };
  interface: InterfaceSettings;
  isSmallScreen: boolean;
  lastPosition: Position;
}

export const UI_INTERFACE_EVERYTHING_FALSE: InterfaceSettings = {
  defaultView: 'issue',
  issueModal: {
    assignee: false,
    reporter: false,
    watcher: false,
    dueDate: false,
    datesInfo: false,
    priority: false,
    tags: false,
    moveTask: false,
    info: false,
    comments: false,
    description: false,
    history: false,
    details: false,
    attachments: false,
    olo_req_log: false,
    delete: false,
    archive: false,
  },
  issueTable: {
    title: false,
    tags: false,
    type: false,
    protocolNumber: false,
    state: false,
    milestone: false,
    suspension: false,
    reporter: false,
    assignee: false,
    priority: false,
    dueDate: false,
    updatedAt: false,
    createdAt: false,
    click: false,
  },
  issueMenu: {
    defaultView: 'list',
    items: {
      list: false,
      board: false,
      calendar: false,
      map: false,
    }
  },
  milestoneMenu: {
    defaultView: 'list',
    items: {
      list: false,
      board: false
    }
  },
  milestoneModal: {
    details: false,
    description: false,
    startDate: false,
    dueDate: false,
    info: false,
  },
  filterSettings: {
    tag: false,
    issueType: false,
    issueState: false,
    milestone: false,
    reporter: false,
    assignee: false,
    priority: false,
  },
  projectMenu: {
    visible: false,
    features: {
      issue: false,
      settings: false,
      archive: false,
      trash: false,
      external_tasks: false,
      export: false,
      milestone: false,
    }
  }
} as const;

export const DEFAULT_UI_INTERFACE: InterfaceSettings = {
  defaultView: 'issue',
  issueModal: {
    assignee: true,
    reporter: true,
    watcher: true,
    dueDate: true,
    datesInfo: true,
    priority: true,
    tags: true,
    moveTask: true,
    info: true,
    comments: true,
    description: true,
    history: true,
    details: true,
    attachments: true,
    olo_req_log: true,
    delete: true,
    archive: true,
  },
  issueTable: {
    title: true,
    tags: true,
    type: true,
    protocolNumber: true,
    state: true,
    milestone: true,
    suspension: true,
    reporter: true,
    assignee: true,
    priority: true,
    dueDate: true,
    updatedAt: true,
    createdAt: true,
    click: true,
  },
  issueMenu: {
    defaultView: 'board',
    items: {
      list: true,
      board: true,
      calendar: true,
      map: true,
    }
  },
  milestoneMenu: {
    defaultView: 'list',
    items: {
      list: true,
      board: true
    }
  },
  milestoneModal: {
    details: true,
    description: true,
    startDate: true,
    dueDate: true,
    info: true,
  },
  filterSettings: {
    tag: true,
    issueType: true,
    issueState: true,
    milestone: true,
    reporter: true,
    assignee: true,
    priority: true,
  },
  projectMenu: {
    visible: true,
    features: {
      issue: true,
      settings: true,
      archive: true,
      trash: true,
      external_tasks: true,
      export: true,
      milestone: true,
    }
  }
} as const;

const initialState: UiState = {
  prjId: null,
  openedInterface: [],
  currentProjectView: null,
  currentProjectSubmenuView: null,
  expandedNavbar: true,
  interface: DEFAULT_UI_INTERFACE,
  isSmallScreen: false,
  lastPosition: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {
  constructor() {
    super(initialState);
    //this.createUIStore().setInitialEntityState(initialState);
  }
}
