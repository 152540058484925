import { Injectable } from "@angular/core";
import { Comments } from "@common/interfaces/comment";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { QueryEntity, QueryConfig, Order } from "@datorama/akita";
import { Observable } from "rxjs";
import { delay, map } from "rxjs/operators";
import { CommentState, CommentStore } from "./comment.store";

@Injectable({
  providedIn: 'root'
})
@QueryConfig({
  sortBy: 'createdAt',
  sortByOrder: Order.ASC
})
export class CommentQuery extends QueryEntity<CommentState> {
  all$ = this.select()
  constructor(protected store: CommentStore) {
    super(store);
  }

  public selectByIssueId$(issueId: ID): Observable<Comments[]> {
    return this.selectAll({
      filterBy: [
        comment => comment.issueId === issueId
      ]
    })
  }

  public getByIssueId(issueId: ID): Comments[] {
    return this.getAll({
      filterBy: [
        comment => comment.issueId === issueId
      ]
    });
  }

}
