import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './project/auth/auth-guard.service';
import { MaintenanceComponent } from './project/pages/maintenance/maintenance.component';
import { MaintenanceGuard } from './project/pages/maintenance/maintenance.guard';

const routes: Routes = [
  {
    path: 'project',
    loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
    canActivate: [AuthGuardService, MaintenanceGuard],
    canLoad: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [MaintenanceGuard],
    canDeactivate: [MaintenanceGuard]
  },
  {
    path: '',
    redirectTo: 'project',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
