import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@ep-om/app.service';
import { SysMessageService } from '@ep-om/project/service/sysMessage.service';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  constructor(private route: Router, private appService: AppService) { }

  interval: NodeJS.Timer;
  ngOnInit(): void {
    this.interval = setInterval( async () => {
      const isMaintenance = await this.appService.requestMaintenance();
      if(!isMaintenance) {
        clearInterval(this.interval);
        localStorage.setItem('MAINTENANCE', JSON.stringify(isMaintenance));
        this.route.navigate(['login']);
      }
    }, Math.random() * 10000 + 1000)
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
