import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { IUser } from '@common/interfaces/user';
import { Store, StoreConfig } from '@datorama/akita';
import { v4 } from 'uuid';

export interface AuthState {
  clientId: string;
  accessToken: string;
  refreshToken: string;
  userId: ID;
  userAgent: string;
  projectRole: { [key: string]: string[] },
  user: IUser,
}

export function createInitialAuthState(): AuthState {
  return {
    clientId: v4(), userAgent: window.navigator.userAgent
  } as AuthState;
  /* let authState = JSON.parse(localStorage.getItem('authStore'))?.auth as AuthState;
  if (!authState) {
    authState = {
      clientId: v4(), userAgent: window.navigator.userAgent
    } as AuthState;
  }
  if (authState && authState.userAgent !== window.navigator.userAgent) {
    authState = { clientId: authState.clientId, userAgent: window.navigator.userAgent } as AuthState;
  }
  return authState; */
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialAuthState());
    if (window.navigator.userAgent !== this.getValue().userAgent) {
      this.update(state => ({... state, userAgent: window.navigator.userAgent}));
    }
  }
}
