<input type="text" 
    nz-input
    #ignoredInput
    [formControl]="formControl"
    [cpAlphaChannel]="'always'"
    [(cpToggle)]="toggle"
    [(colorPicker)]="color"
    [cpIgnoredElements]="[readonly && ignoredInput]"
    [readonly]="readonly"
    (colorPickerChange)="changeColor($event)"
/>