import { Injectable } from "@angular/core";
import { IIssue } from "@common/interfaces/issue";
import { combineQueries, QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import { delay, filter, map, switchMap } from "rxjs/operators";
import { IssueQuery } from "../issue/issue.query";
import { ProjectQuery } from "../project/project.query";
import { UserQuery } from "../user/user.query";
import { CommentQuery } from "../comment/comment.query";
import { LastUpdateState, LastUpdateStore } from "./lastUpdate.store";
import { ChildInfo, LastUpdate } from "./lastUpdate.model";

@Injectable({
  providedIn: 'root'
})
export class LastUpdateService {
  constructor(
    private _store: LastUpdateStore,
    private projectQuery: ProjectQuery,
    private userQuery: UserQuery,
    private issueQuery: IssueQuery,
    private commentQuery: CommentQuery
  ) {
  }

  setLastUpdate(storeName: string, date: string) {
    const partial: Partial<LastUpdateState> = {};
    partial[storeName] = date;
    this._store.update(state => {
      return {
        ...state,
        ...partial
      }
    })
  }

  cleanLastUpdate(storeName: string) {
    this._store.update(state => {
      const {Projects, ...newState} = state;
      return newState;
    });
  }

  cleanChildLastUpdate(projectId: string) {
    const state = this._store.getValue();
    let newState = {...state}
    for (const key in newState) {
      if(key.startsWith(`PRJ_${projectId}_`)) {
        delete newState[key];
      }
    }
    this._store._setState(newState);
  }

  setChildLastUpdate(projectId: string, childStoreName: string, date:string) {
    this._store.update( state => {
      const partial: Partial<LastUpdateState> = {}
      const childKey = `PRJ_${projectId}_${childStoreName}`;
      partial[childKey] = date;
      return {
        ...state,
        ...partial
      }
    })
  }

}
