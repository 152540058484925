import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiStore, UiState } from './ui.store';

@Injectable({ providedIn: 'root' })
export class UiQuery extends Query<UiState> {
  interface$ = this.select('interface');
  expandedNavbar$ = this.select('expandedNavbar');
  currentProjectId$ = this.select('prjId');
  isSmallScreen$ = this.select('isSmallScreen');
  
  constructor(protected store: UiStore) {
    super(store);
  }

  getCurrentProjectView() {
    return this.getValue().currentProjectView;
  }

  getCurrentProjectSubmenuView(submenu: string) {
    const currentProjectSubmenuView = this.getValue().currentProjectSubmenuView;
    return currentProjectSubmenuView ? currentProjectSubmenuView[submenu] : null;
  }

  getLastPosition() {
    return this.getValue().lastPosition;
  }

  getIsSmallScreen() {
    return this.getValue().isSmallScreen;
  }

}
