import { Injectable } from '@angular/core';
import { IIDolServizioAggiuntivoStore } from './iidOlServizioAggiuntivo.store';
import { IID_OL_SERVIZIO_AGGIUNTIVO } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolServizioAggiuntivoQuery } from './iidOlServizioAggiuntivo.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolServizioAggiuntivoService extends CrudService<IID_OL_SERVIZIO_AGGIUNTIVO, IIDolServizioAggiuntivoStore, IIDolServizioAggiuntivoQuery> {
  constructor(
    protected store: IIDolServizioAggiuntivoStore,
    protected query: IIDolServizioAggiuntivoQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      Topics.IID_OL_SERVIZIO_AGGIUNTIVO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
