import { Injectable } from "@angular/core";
import { IID_EP_SUPPORT_GISFO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDepGisfoSupportStore } from "./iidEPGisfoSupport.store";

@Injectable({
  providedIn: 'root'
})
export class IIDepGisfoSupportQuery extends AbstractInterfaceDataQuery<IID_EP_SUPPORT_GISFO> {

  constructor(protected store: IIDepGisfoSupportStore) {
    super(store);
  }
}
