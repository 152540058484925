export enum AppFeatures {
    ARCHIVE = "archive",
    TRACE = "trace",
    EXCEL_EXPORT = "excel_export",
    FORGOT_PASSWORD = "forgot_password",
    ATTACHMENT = 'attachment',
    OLO_REQ_LOG = 'olo_req_log',
    REPORT_FTTH = 'report_ftth',
    TAGS = 'tags',
    REGISTER = 'register',
    TRANSFER_TICKETS = 'transfer_tickets',
    DASHBOARD = 'dashboard',
    I18N = 'i18n',
    MILESTONE = 'milestone',
    APPOINTMENT_CALENDAR = "appointment_calendar",
    MAP = "map",
};

export type DisplayType = "DISABLED" | "OFFLINE" | "ONLINE";

export type FeatureEnvironmentType = "OFFLINE" | "ONLINE";

export const FeatureEnvironmentAssociation: { [P in AppFeatures]: FeatureEnvironmentType } = {
    archive: "ONLINE",
    trace: "ONLINE",
    attachment: "ONLINE",
    excel_export: "OFFLINE",
    forgot_password: "ONLINE",
    olo_req_log: "ONLINE",
    report_ftth: "ONLINE",
    tags: "OFFLINE",
    register: "ONLINE",
    transfer_tickets: "OFFLINE",
    dashboard: "ONLINE",
    i18n: "ONLINE",
    milestone: "ONLINE",
    appointment_calendar: "ONLINE",
    map: "ONLINE",
}

export type FeatureDisplay = {
    [P in AppFeatures]: DisplayType;
};

export const defaultFeatureDisplay: FeatureDisplay = {
    archive: "DISABLED",
    trace: "DISABLED",
    attachment: "DISABLED",
    excel_export: "DISABLED",
    forgot_password: "DISABLED",
    olo_req_log: "DISABLED",
    report_ftth: "DISABLED",
    tags: "DISABLED",
    register: "DISABLED",
    transfer_tickets: "DISABLED",
    dashboard: "DISABLED",
    i18n: "DISABLED",
    milestone: "DISABLED",
    appointment_calendar: "DISABLED",
    map: "DISABLED",
}
