import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '@common/interfaces/user';

@Component({
  selector: 'j-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user: IUser;
  @Input() size: number = 24;
  @Input() showName: boolean = true;
  @Input() bold: boolean;

  style: {[key: string]: any};
  constructor() {}
  ngOnInit(){
    if(this.bold){
      this.style = {
        'font-weight': 600
      }
    }
  }
}
