import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { IProject, IProjectSettings, IResourceManagement } from '@common/interfaces/project';
import { WorkFlowSettings } from '@common/interfaces/workflow';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { sha1 } from 'object-hash';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ProjectState, ProjectStore, ProjectUIState } from './project.store';
import { WorkflowQuery } from '../workflow/workflow.query';
@Injectable({
  providedIn: 'root'
})
export class ProjectQuery extends QueryEntity<ProjectState> {
  //collection: EntityStateHistoryPlugin<ProjectState>;
  ui: EntityUIQuery<ProjectUIState>;
  isLoading$ = this.selectLoading();
  all$ = this.selectAll();
  //all$: Observable<IProject[]>;
  active$ = this.selectActive();
  activeId$ = this.selectActiveId();
  activeNotNull$ = this.selectActive().pipe(filter(project => !!project));
  suspensionOn$ = this.active$.pipe(
    filter(project => !!project), 
    map(project => this.workflowQuery.getEntity(project.workflowId)?.settings?.suspension?.enabled), 
    catchError(error => {
      console.log(error);
      return of(null);
    })
  )

  activeWorkflowSettings$(): Observable<WorkFlowSettings> {
    return this.workflowQuery.selectWorkflowSettingsByWorkflowId$(this.getActive()?.workflowId);
  };
  
  selectProjectsByWorkflow$(workfloId: string) {
    return this.selectAll({
      filterBy: (entity) => entity.workflowId === workfloId
    })
  }

  getProjectsByWorkflowId(workfloId: string) {
    return this.getAll({
      filterBy: (entity) => entity.workflowId === workfloId
    });
  }

  // selectFavorites$: Observable<IProject[]>;
  //issues$ = this.selectActive(entity => entity.issues)
  //users$ = this.selectActive(entity => entity.users);

  constructor(
    protected store: ProjectStore,
    protected workflowQuery: WorkflowQuery
  ) {
    super(store);
    this.createUIQuery();

    // this.selectFavorites$ = combineLatest([
    //   this.all$,
    //   this.ui.selectAll()
    // ]).pipe(map(([projects, ui]) => {
    //   return projects.filter(p => {
    //     let uiFound = ui.find(ui => ui.id === p.id);
    //     return uiFound && uiFound.isFavorite;
    //   });
    // }));
    //this.collection = new EntityStateHistoryPlugin(this);

  }

  getWorkflowSettingsByProjectId(projectId: ID) {
    if(!projectId) { return; }
    const project = this.getEntity(projectId);
    return this.workflowQuery.getEntity(project?.workflowId).settings;
  }

  getActiveWorkflowSettings() {
    const project = this.getActive();
    return this.workflowQuery.getEntity(project?.workflowId).settings; 
  }

  getSettings() {
    return this.getActive().settings;
  }

  activeSettings$: Observable<IProjectSettings> = this.selectActive().pipe(
      filter((project) => !!project),
      distinctUntilChanged((x,y) => { return  sha1(x?.settings) === sha1(y?.settings)}),
      map(project => project.settings)
    );

  selectOriginalProject(id: string): Observable<IProject>  {
    return this.selectEntity(id);
  }

  selectResourceManagement(): Observable<IResourceManagement> {
    return this.selectActive().pipe(
      map(prj => this.workflowQuery.getEntity(prj?.workflowId)?.settings?.resourceManagement)
    );
  }

  selectResourceManagementAllProjects(): Observable<{ [key: string]: IResourceManagement }> {
    return this.selectAll().pipe(map(projects => {
      return projects.reduce((acc, currPrj) => {
        acc[currPrj.id] = this.workflowQuery.getEntity(currPrj?.workflowId)?.settings?.resourceManagement;
        return acc;
      }, {})
    }));
  }

}
