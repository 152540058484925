import { Injectable } from '@angular/core';
import { AuthStore, AuthState } from './auth.store';
import { Query, selectPersistStateInit, toBoolean } from '@datorama/akita';
import { distinctUntilChanged, filter, map, pairwise, take } from 'rxjs/operators';
import { sha1 } from 'object-hash';
import { combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  authState$ = this.select();
  userId$ = this.select('userId');
  accessToken$ = this.select('accessToken');
  refreshToken$ = this.select('refreshToken');
  roles$ = this.select('projectRole');
  loggedIn$ = this.select('accessToken').pipe(map(at=>!!at));
  user$ = this.select('user');
  projectRole$ = this.select('projectRole');

  constructor(protected store: AuthStore) {
    super(store);
  }

  getClientId(): string {
    return this.getValue().clientId;
  }

  isLoggedIn() {
    return toBoolean(this.getValue().accessToken);
  }

  getLoggedUserId(): string {
    return this.getValue().userId;
  }

  getAccessToken(): string {
    return this.getValue().accessToken;
  }

  getProjectRoles() {
    return this.getValue().projectRole;
  }

  selectProjectRole$() {
    return this.select('projectRole').pipe(filter(projectRole => !!projectRole));
  }

  selectProjectRoleByProjectId$(projectId) {
    return this.select('projectRole').pipe(
      map(roles => roles[projectId]),
      distinctUntilChanged((prev, curr) => sha1(prev) === sha1(curr))
    )
  }
}
