import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { of, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, distinctUntilChanged, map, take, timeout } from 'rxjs/operators';
import { AuthQuery } from '@ep-om/project/auth/auth.query';
import { environment } from 'src/environments/environment';


export enum SubscriptionStates { None = "none", Removed = "removed", Ok = "ok" }
export enum EventType {
  SubscriptionSaved = "subscription_saved",
}
export interface Event { type: EventType, info: any }
export type PushNotification = NotificationOptions & {
  title: string
  msgType?: string,
  prjName?: string,
  prjEmail?: string,
  prjPhoneNumber?: string,
  prjUrl?: string,
}//interfaccia da controllare
export interface PushMessage { }
export interface NotificationClick { action: any, notification: PushNotification }

@Injectable({
  providedIn: 'root'
})
export class WebpushNotificationService {

  private readonly backendUrl: string = `${environment.apiUrl}/api/`;
  clientId: string;
  userId: string;
  // workspace:string;
  // userEmail:string;
  // private clientApiKey:string;
  granted: boolean = false;
  subscriptionState: SubscriptionStates = SubscriptionStates.None;
  notifications$: Subject<PushNotification> = new Subject<PushNotification>();
  messages$: Subject<PushMessage> = new Subject<PushMessage>();
  clicks$: Subject<NotificationClick> = new Subject<NotificationClick>();
  events$: Subject<Event> = new Subject<Event>();
  //private httpHeaders:HttpHeaders;
  private subscriptions: Subscription[] = [];

  constructor(readonly swPush: SwPush, private http: HttpClient, authQuery: AuthQuery) {

    authQuery.authState$.pipe(
      distinctUntilChanged((x, y) => x.clientId !== y.clientId || x.userId !== y.userId),
      map(i => { return { clientId: i.clientId, userId: i.userId } }),
    ).subscribe(async (as) => {
      this.clientId = as.clientId;
      this.userId = as.userId;
      if (this.userId)
        await this.setup();
      // else
      //   await this.clean();
    });

  }

  /**
   * Called by Logout in AuthService
   */
  public async clean() {

    if (this.clientId) {
      this.unsubscribeAll();
      await this.unsubscribePush();
      this.persistSubscription(null);
    }

  }

  public async setup() {

    this.unsubscribeAll();

    this.subscriptions.push(this.swPush.messages.subscribe(async (m: any) => {
      console.log("[WP] swPush:messages", m);
      if (m.notification) {
        this.notifications$.next(m.notification)
      } else {
        this.messages$.next(m);
      }
    }));

    this.subscriptions.push(this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      console.log("[WP] swPush:notificationClicks", action, notification);
      this.clicks$.next({ action, notification })
    }));

    this.subscriptions.push(this.swPush.subscription.pipe(distinctUntilChanged((prev, curr) => prev && curr && prev.endpoint === curr.endpoint)).subscribe(s => {
      console.log("[WP] swPush:subscription", s);
      this.persistSubscription(s);
    }));

    if ('Notification' in window) {

      window.Notification.requestPermission(async (result) => {
        console.log("[WP] requestPermission", result);
        this.granted = result === "granted";
        if (this.granted) {
          this.http.get(`${this.backendUrl}wpn/publicKey`).subscribe(async (rv: { key: string }) => {
            console.log("[WP] swPush:requestSubscription", rv);
            await this.swPush.requestSubscription({ serverPublicKey: rv.key });
          });
        }
      });

    } else {

      this.persistSubscription(null);

    }
  }

  private async unsubscribePush() {
    try {
      const subscriptions = await this.swPush.subscription.pipe(take(1), timeout(2000), catchError(err => of(null))).toPromise();
      if(subscriptions) {
        await this.swPush.unsubscribe();
      }
    } catch (err) {
      console.error('Could not unsubscribe due to:', err);
    }
  }

  private unsubscribeAll() {
    while (this.subscriptions.length > 0) this.subscriptions.shift().unsubscribe();
  }

  // in questo caso quando s è null si intende rimuovi la sottoscrizione
  private persistSubscription(s: any) {

    this.subscriptionState = s ? SubscriptionStates.Ok : SubscriptionStates.Removed;

    this.http.post(`${this.backendUrl}wpn/subscribe`, {
      clientId: this.clientId,
      userId: this.userId,
      subscriptionEndpoint: s
    }).subscribe((rv) => {
      this.events$.next({ type: EventType.SubscriptionSaved, info: this.subscriptionState });
    });

  }
}
