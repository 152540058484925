import { Injectable } from '@angular/core';
import { IIDgenericSuspensionStore } from './genericSuspension.store';
import { IID_GENERIC_SUSPENSION } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDgenericSuspensionQuery } from './genericSuspension.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDgenericSuspensionService extends CrudService<IID_GENERIC_SUSPENSION, IIDgenericSuspensionStore, IIDgenericSuspensionQuery> {
  constructor(
    protected store: IIDgenericSuspensionStore,
    protected query: IIDgenericSuspensionQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_GENERIC_SUSPENSION,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
