import { Injectable } from '@angular/core';
import { IID_GR_TASKS } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDGrTasksQuery } from './iidGRTasks.query';
import { IIDGrTasksStore } from './iidGRTasks.store';

@Injectable({
  providedIn: 'root'
})
export class IIDGrTasksService extends CrudService<IID_GR_TASKS, IIDGrTasksStore, IIDGrTasksQuery> {

  constructor(
    protected store: IIDGrTasksStore,
    protected query: IIDGrTasksQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      Topics.IID_GR_TASKS,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
