import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { SysMessage } from "@common/interfaces/sysMessage";
import { News } from "@common/interfaces/user";
import { SocketIoService } from "@ep-om/core/services/socket-io.service";
import { filter, switchMap } from "rxjs/operators";
import { Subject } from "rxjs";
import { ProjectService } from "../state/project/project.service";
import { Router } from "@angular/router";
import { resetStores } from "@datorama/akita";
import { HttpClient } from "@angular/common/http";
import { AppService } from "@ep-om/app.service";


@Injectable({
    providedIn: 'root',
})
export class SysMessageService {
    private _sysMessages$ = new Subject<SysMessage>();
    public sysMessages$ = this._sysMessages$.asObservable();

    constructor(
      private socketIoService: SocketIoService,
      private projectService: ProjectService,
      private route: Router,
      private http: HttpClient,
      private appService: AppService
    ) {
        this.keepBadgeUpdated();
        this.ManageMaintenance();
        this.manageMultipleTabs();
        this.socketIoService.uponConnection$.pipe(
            switchMap(() => this.socketIoService.listenEntity<SysMessage>(Topics.SYSMESSAGES)),
            this.socketIoService.stopOnDisconnectionPipe(),
            this.socketIoService.repeatOnConnectionPipe()
        ).subscribe((msg) => {
            this._sysMessages$.next(msg.data as unknown as SysMessage);
        });
    }

    private ManageMaintenance() {
        this._sysMessages$.pipe(
            filter(sysMessage => sysMessage.topic === 'MAINTENANCE')
        ).subscribe((maintenance: any) => {
            this.appService.setMaintenance(maintenance.payload.isMaintenance);
        })
    }

    public getMaintenance(): boolean {
        let inMaintenance = false;
        const temp = localStorage.getItem('MAINTENANCE');
        if(temp === 'true' || temp === 'false') {
            inMaintenance = temp === 'true' || false;
        }
        return inMaintenance;
    }

    private manageMultipleTabs() {
      this._sysMessages$.pipe(
        filter(sysMessage => sysMessage.topic === 'CLIENT/INFO')
      ).subscribe(info => {
        this.appService.updateMaintab(info.payload.mainClient);
      })
    }
    

    private keepBadgeUpdated() {
        this._sysMessages$.pipe(
            filter(sysMessage => sysMessage.topic === 'NEWS/PROJECT')
        ).subscribe(p => {
            if (p.payload.newsToTell || p.payload.newsTold) {
                const payload: { newsToTell?: News[], newsTold?: News[] } = p.payload;
                payload.newsToTell?.forEach(news => {
                    this.projectService.addBadges(news.project, news.issues)
                })
                payload.newsTold?.forEach(news => {
                    this.projectService.addBadges(news.project, news.issues)
                })
            } else if (p.payload.project) {
                this.projectService.addBadges(p.payload.project, p.payload.issues);
                // modifiche avvenute ora
                //TODO madinamo fumetto per modifica su progetto che non stiamo guardando?
            }
        })
    }
}
