import { Injectable } from '@angular/core';
import { IAppointment } from '@common/interfaces/appointment';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { BaseEntityStoreStrategy } from '../updateStoreStrategies';
import { AppointmentQuery } from './appointment.query';
import { AppointmenteStore } from './appointment.store';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends CrudService<IAppointment, AppointmenteStore, AppointmentQuery> {

  constructor(
    protected store: AppointmenteStore,
    public query: AppointmentQuery, 
    protected actionService: ActionService,
    protected updateStrategy: BaseEntityStoreStrategy
  ) { 
    super(
      Topics.APPOINTMENT,
      store,
      query,
      actionService,
      updateStrategy
    )
  }

  localRemoveByUserId(userIds: string[]) {
    this.store.remove(({ userId }) => userIds.includes(userId));
  }
}
