import { Injectable } from "@angular/core";
import { IID_META_INTERFACE } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDMetaInterfaceStore } from "./iid-meta-interface.store";

@Injectable({
  providedIn: 'root'
})
export class IIDMetaInterfaceQuery extends AbstractInterfaceDataQuery<IID_META_INTERFACE> {
  constructor(protected store: IIDMetaInterfaceStore) {
    super(store);
  }
}
