import { Injectable } from '@angular/core';
import { IID_META_INTERFACE } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDMetaInterfaceQuery } from './iid-meta-interface.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { WsACKCallback } from '@common/api/ack';
import { IIDMetaInterfaceStore } from './iid-meta-interface.store';

const TOPIC = Topics.IID_META_INTERFACE
@Injectable({
  providedIn: 'root'
})
export class IIDMetaInterfaceService extends CrudService<IID_META_INTERFACE, IIDMetaInterfaceStore, IIDMetaInterfaceQuery> {
  constructor(
    protected store: IIDMetaInterfaceStore,
    public query: IIDMetaInterfaceQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      TOPIC,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  // refresh(id: string, callback?: WsACKCallback) {
  //   this.actionService.sendMessage(TOPIC, 'refresh', {id}, callback)
  // }

}
