import { Component, Input, OnInit } from '@angular/core';
import Color from 'color';
import ColorHash from 'color-hash'

@Component({
  selector: 'j-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input() avatar:string;
  @Input() size = 12;
  @Input() name = '';
  @Input() rounded = true;
  @Input() className = '';
  @Input() text:string;
  @Input() svg:string;
  @Input() bgColor:string;
  @Input() value:string;
  @Input() isActiveSomeProject: boolean;
  @Input() setBadge: boolean = false;
  @Input() newsCount: number;
  @Input() issueCard: boolean = false;

  ch = new ColorHash();

  constructor() {}

  ngOnInit(): void {
    this.setBadge
    this.isActiveSomeProject;
    this.newsCount;
  }

  get _bgColor():string {
    return this.bgColor || this.ch.hex(this.text || this.valueText || "");
  }

  get _txtColor():string {
    const bg = new Color(this._bgColor); 
    return bg.isDark() ?  'white' : 'black';
  }

  get _containerStyle() {
    const rv = !this.issueCard ? this._imgStyle : this._imgStyleIssueCard;
    if(this.bgColor || this.text || this.valueText) rv['background-color'] = `${this._bgColor}`;
    return rv;
  }

  get _imgStyle() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      'border-radius': this.rounded ? '100%' : '4px'
    };
  }

  get _imgStyleIssueCard() {
    return {
      width: `30px`,
      height: `100%`,
      'border-radius': this.rounded ? '100%' : '2px'
    };
  }

  get _txtStyle() {
    return {
      'color': `${this._txtColor}`,
      'font-size': `${ Math.trunc(this.size/2.75) }pt`
    }; 
  }

  get _txtStyleIssueCard() {
    return {
      'color': `${this._txtColor}`,
      'font-size': `15pt`,
      'align-self': 'end',
      'transform': 'rotate(-90deg)',
      'padding-left': '8px',
      'width': `100%`
    }; 
  }

  get _svg() {
    return this.svg || this.valueSVG;
  }

  get _avatar() {
    return !this._svg ? (this.avatar || this.valueAvatar) : null;
  }

  get _text() {
    return (!this._avatar && !this._svg) ? (this.text || this.valueText) : null;
  }

  get isValueText() {
    return this.value && this.value.startsWith("txt:");
  }

  get valueText() {
    return this.isValueText ? this.value.substr(4) : null;
  }

  get isValueSVG() {
    return this.value && this.value.startsWith("svg:");
  }

  get valueSVG() {
    return this.isValueSVG ? this.value.substr(4) : null;
  }

  get isValueAvatar() {
    return !this.isValueText && !this.isValueSVG;
  }

  get valueAvatar() {
    return this.isValueAvatar ? this.value : null;
  }

  

}
