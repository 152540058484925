import { IUser } from '@common/interfaces/user';
//import { IssueTypeWithIcon } from '@common/interfaces/issue-type';

export class ProjectConst {
  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly IssueId = 'issueId';
  static readonly Projects = 'Projects';
  // static readonly splitCamel = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
  // static readonly capitalize = /(\b^[a-z](?!\s))/g;

  static readonly orederManager: IUser = {
    name: 'Order Manager',
    username: 'omanager',
    avatar: '/assets/logo.svg',
    id: '',
    // company: '', // CHECK COMPANY !
    email: '',
    password: '',
    createdAt: '',
    updatedAt: '',
    settings: null
  }

  static readonly BASEISSUE = [
    {field:"ISSUE:CREATEDAT",label:"ISSUE:Data di creazione"},
    {field: "ISSUE:SUSPENSION", label: "ISSUE:Sospensione"},
    //{field:"ISSUE:UPDATEDAT",label:"ISSUE:UPDATEDAT"},
    //{field:"ISSUE:DELETEDAT",label:"ISSUE:DELETEDAT"},
    //{field:"ISSUE:VERSION",label:"ISSUE:VERSION"},
    // {field:"ISSUE:CREATEDBY",label:"ISSUE:CREATEDBY"},
    {field:"ISSUE:ID",label:"ISSUE:Identificativo"},
    {field:"ISSUE:TITLE",label:"ISSUE:Titlo"},
    // {field:"ISSUE:PROTOCOLNUMBER",label:"ISSUE:PROTOCOLNUMBER"},
    //{field:"ISSUE:TYPEID",label:"ISSUE:TYPEID"},
    {field:"ISSUE:STATEID",label:"ISSUE:Stato"},
    //{field:"ISSUE:WORKFLOWID",label:"ISSUE:WORKFLOWID"},
    {field:"ISSUE:PRIORITY",label:"ISSUE:Priorità"},
    //{field:"ISSUE:DESCRIPTION",label:"Descrizione"},
    // {field:"ISSUE:ESTIMATE",label:"Tempo estimato"},
    // {field:"ISSUE:TIMESPENT",label:"Tempo impiegato"},
    // {field:"ISSUE:TIMEREMAINING",label:"Tempo rimasto"},
    {field:"ISSUE:DUEDATE",label:"ISSUE:Scadenza"},
    {field:"ISSUE:REPORTERID",label:"ISSUE:Reporter"},
    {field:"ISSUE:ASSIGNEEID",label:"ISSUE:Assegnatario"},
  ];

  static readonly ASSETS = [
    {
      field: "ASSETS:TIPOLOGIA",
      label: "ASSETS:Tipologia"
    }, 
    {
      field: "ASSETS:ASSETS:MARCA",
      label: "ASSETS:Marca"
    }, 
    {
      field: "ASSETS:ASSETS:MODELLO",
      label: "ASSETS:Modello"
    }, 
    {
      field: "ASSETS:DESTINATARIO",
      label: "ASSETS:Destinatario"
    }, 
    {
      field: "ASSETS:INFO",
      label: "ASSETS:Info"
    }
  ];

  static readonly CLIENTE = [
    {field:"CLIENTE:NOME",label:"CLIENTE:Nome"},
    {field:"CLIENTE:COGNOME",label:"CLIENTE:Cognome"},
    {field:"CLIENTE:RECAPITOTELEFONICO1",label:"CLIENTE:Telefono 1"},
    {field:"CLIENTE:RECAPITOTELEFONICO2",label:"CLIENTE:Telefono 2"},
    {field:"CLIENTE:EMAIL",label:"CLIENTE:Email cliente"},
    {field:"CLIENTE:CODICEFISCALE",label:"CLIENTE:Codice fiscale"},
    {field:"CLIENTE:INFORMAZIONILOGISTICAAPPARATI",label:"CLIENTE:Informazioni logistica apparati"}
  ];

  static readonly CONDOMINIO = [
    {field:"CONDOMINIO:AMMINISTRATORE",label:"CONDOMINIO:Amministratore"},
    {field:"CONDOMINIO:INDIRIZZOSTUDIO",label:"CONDOMINIO:Indirizzo Studio"},
    {field:"CONDOMINIO:TELEFONO",label:"CONDOMINIO:Telefono"},
    {field:"CONDOMINIO:EMAIL",label:"CONDOMINIO:Email Amministratore"},
  ];

  static readonly RIFERIMENTI = [
    {field:"RIFERIMENTI:IDBUILDING",label:"RIFERIMENTI:ID Building"},
    {field:"RIFERIMENTI:SCALAPALAZZINA",label:"RIFERIMENTI:Scala palazzina"},
    {field:"RIFERIMENTI:NUMEROTELEFONICOPRINCIPALELINEA",label:"RIFERIMENTI:Numero telefonico principale"},
    {field:"RIFERIMENTI:RECAPITOTESTLINEA",label:"RIFERIMENTI:Recapito Test Linea"},
    {field:"RIFERIMENTI:IDENTIFICATIVOPOP",label:"RIFERIMENTI:Identificativo Pop"},
    {field:"RIFERIMENTI:GPONRICHIESTA",label:"RIFERIMENTI:GPON Richiesta"},
    {field:"RIFERIMENTI:CODICEOPERATORESECONDARIO",label:"RIFERIMENTI:Codice operatore secondario"},
    {field:"RIFERIMENTI:PARTICELLATOPONOMASTICA",label:"RIFERIMENTI:Particella Toponomastica"},
    {field:"RIFERIMENTI:INDIRIZZO",label:"RIFERIMENTI:Indirizzo"},
    {field:"RIFERIMENTI:NUMEROCIVICO",label:"RIFERIMENTI:Numero Civico"},
    {field:"RIFERIMENTI:POP",label:"RIFERIMENTI:POP"},
    {field:"RIFERIMENTI:PTE",label:"RIFERIMENTI:PTE"},
    {field:"RIFERIMENTI:RIFERIMENTOUI",label:"RIFERIMENTI:Riferimento UI"}
  ];

  static readonly OLO_REQUEST = [
    {field:"OLO_REQUEST:CODICEOPERATORE",label:"OLO_REQUEST:Codice Operatore"},
    {field:"OLO_REQUEST:CODICEORDINEOLO",label:"OLO_REQUEST:Codice Ordine Olo"},
    {field:"OLO_REQUEST:DATANOTIFICA",label:"OLO_REQUEST:Data Notifica"},
    {field:"OLO_REQUEST:IDNOTIFICA",label:"OLO_REQUEST:Id Notifica"},
    {field:"OLO_REQUEST:NOMEREFERENTETECNICOOLO",label:"OLO_REQUEST:Nome Referente Tecnico Olo"},
    {field:"OLO_REQUEST:COGNOMEREFERENTETECNICOOLO",label:"OLO_REQUEST:Cognome Referente Tecnico Olo"},
    {field:"OLO_REQUEST:TELEFONOREFERENTETECNICOOLO",label:"OLO_REQUEST:Telefono referente tecnico olo"},
    {field:"OLO_REQUEST:EMAILREFERENTETECNICOOLO",label:"OLOREQUEST:Email Referente Tecnico Olo"},
    {field:"OLO_REQUEST:TELEFONOREFERENTEOLOONFIEDNOTECH",label:"OLO_REQUEST:Telefono Referente Olo Onfield Notech"}
  ];

  static readonly CONTRATO = [
    {field:"CONTRATTO:DATAPREVISTAATTIVAZIONE",label:"CONTRATTO:Data prevista attivazione"},
    {field:"CONTRATTO:IDENTIFICATIVOPRENOTAZIONE",label:"CONTRATTO:Identificativo prenotazione"},
    {field:"CONTRATTO:ORARIOAPPUNTAMENTO",label:"CONTRATTO:Orario appuntamento"},
    {field:"CONTRATTO:CODICEPROGETTOSPECIALE",label:"CONTRATTO:Codice progetto speciale"},
    {field:"CONTRATTO:PROMOZIONE",label:"CONTRATTO:Promozione"},
    {field:"CONTRATTO:CLUSTERPROMOZIONE",label:"CONTRATTO:Cluster promozione"},
    {field:"CONTRATTO:NOTE",label:"CONTRATTO:Note"}
  ];

  static readonly SERVIZIO_AGGIUNTIVO = [
    {field:"SERVIZIO_AGGIUNTIVO:NOME",label:"SERVIZIO_AGGIUNTIVO:Nome"},
    {field:"SERVIZIO_AGGIUNTIVO:INFORMAZIONI",label:"SERVIZIO_AGGIUNTIVO:Informazioni"}
  ];

  static readonly APPARATO = [
    {field:"APPARATO:TIPOLOGIAAPPARATO",label:"APPARATO:Tipologia Apparato"},
    {field:"APPARATO:PASSWORDAPPARATO",label:"APPARATO:Password Apparato"},
    {field:"APPARATO:AZIONEAPPARATO",label:"APPARATO:Azione Apparato"},
    {field:"APPARATO:CAUSALE",label:"APPARATO:Causale"},
  ];

  static readonly APPUNTAMENTO = [
    {field:"APPUNTAMENTO:TIME", label:"APPUNTAMENTO:Ora dell'appuntamento"},
    {field:"APPUNTAMENTO:DATE", label:"APPUNTAMENTO:Data dell'appuntamento"},
    {field:"APPUNTAMENTO:CASUALE", label:"APPUNTAMENTO:Motivazione"},
    {field:"APPUNTAMENTO:IMPRESA", label:"APPUNTAMENTO:Impresa"},
  ];

  static readonly ESPLETAMENTO_NEGATIVO = [
    {field:"ESPLETAMENTO_NEGATIVO:MOTIVAZIONE", label:"ESPLETAMENTO_NEGATIVO:Motivazione"}
  ];

  static readonly ACCETTAZIONE_NEGATIVA = [
    {field:"ACCETTAZIONE_NEGATIVA:MOTIVAZIONE", label:"ACCETTAZIONE_NEGATIVA:Motivazione"},
    {field:"ACCETTAZIONE_NEGATIVA:NOTE", label:"ACCETTAZIONE_NEGATIVA:Note"}
  ];

  static readonly ACQUISIZIONE_NEGATIVA = [
    {field:"ACQUISIZIONE_NEGATIVA:MOTIVAZIONE", label:"ACQUISIZIONE_NEGATIVA:Motivazione"},
    {field:"ACQUISIZIONE_NEGATIVA:NOTE", label:"ACQUISIZIONE_NEGATIVA:Note"}
  ];

  static readonly ANNULLATO = [
    {field:"ANNULLATO:MOTIVAZIONE", label:"ANNULLATO:Motivazione"},
  ];

  static readonly ESPLETAMENTO_POSITIVO = [
    {field:"ESPLETAMENTO_POSITIVO:MOTIVAZIONE", label:"ESPLETAMENTO_POSITIVO:Motivazione"},
    {field:"ESPLETAMENTO_POSITIVO:IDRISORSA", label:"ESPLETAMENTO_POSITIVO:ID Risorsa"},
    {field:"ESPLETAMENTO_POSITIVO:IDSPLITTERSECONDARIO", label:"ESPLETAMENTO_POSITIVO:ID Splitter Secondario"},
    {field:"ESPLETAMENTO_POSITIVO:PARAMETRIOTTICI", label:"ESPLETAMENTO_POSITIVO:Parametri trasmissivi ottici"},
    {field:"ESPLETAMENTO_POSITIVO:POSIZIONESPLITTERSECONDARIO", label:"ESPLETAMENTO_POSITIVO:Posizione Splitter Secondario"}
  ];

  static readonly OLO_SOSPENSIONE = [
    {field:"OLO_SOSPENSIONE:CAUSA", label:"OLO_SOSPENSIONE:Causa"},
    {field:"OLO_SOSPENSIONE:CAUSALE", label:"OLO_SOSPENSIONE:Codice Motivazione"},
    {field:"OLO_SOSPENSIONE:NOTE", label:"OLO_SOSPENSIONE:Note"}
  ];

  static readonly RICHIESTA_PERMESSO = [
    {field:"RICHIESTA_PERMESSO:ENTE", label:"RICHIESTA_PERMESSO:Ente"},
    {field:"RICHIESTA_PERMESSO:TIPOLOGIA", label:"RICHIESTA_PERMESSO:Tipologia"},
    {field:"RICHIESTA_PERMESSO:STRADA", label:"RICHIESTA_PERMESSO:Strada"},
    {field:"RICHIESTA_PERMESSO:ISTANZA", label:"RICHIESTA_PERMESSO:Istanza"},
    {field:"RICHIESTA_PERMESSO:INVIO", label:"RICHIESTA_PERMESSO:Data invio istanza"}
  ];

  static readonly RISPOSTA = [
    {field:"RISPOSTA:PROTOCOL", label:"RISPOSTA:Protocol"},
    {field:"RISPOSTA:OGGETTO", label:"RISPOSTA:Oggetto"},
    {field:"RISPOSTA:DATE", label:"RISPOSTA:Data"}
  ];

  static readonly RISPOSTA_SOLLECITO = [
    {field:"RISPOSTA_SOLLECITO:STATUS", label:"RISPOSTA_SOLLECITO:OLO Status"},
    {field:"RISPOSTA_SOLLECITO:UNBLOCK", label:"RISPOSTA_SOLLECITO:Data sblocco"},
    {field:"RISPOSTA_SOLLECITO:NOTE", label:"RISPOSTA_SOLLECITO:Note"}
  ];

  static readonly OLO_RISOLUZIONE = [
    {field:"OLO_RISOLUZIONE:ESITO", label:"OLO_RISOLUZIONE:Esito"},
    {field:"OLO_RISOLUZIONE:CLASSIFICATION", label:"OLO_RISOLUZIONE:Classificazione"},
    {field:"OLO_RISOLUZIONE:ACTIVITY", label:"OLO_RISOLUZIONE:Attività"}
  ];

  static readonly INSTALLAZIONE = [
    {field:"INSTALLAZIONE:IDRISORSA", label:"INSTALLAZIONE:ID Risorsa"}
  ];

  static readonly ORDINE_OLO = [
    {field:"ORDINE_OLO:CODICEORDINEOLO", label:"ORDINE_OLO:Codice Ordine", displayedLabel: "Codice Ordine"}
  ];

  static readonly OLO_COMMUNICATION = [
    {field:"OLO_COMMUNICATION:ERROR", label:"OLO_COMMUNICATION:Errore"},
    {field:"OLO_COMMUNICATION:ERRORCODE", label:"OLO_COMMUNICATION:Codice errore"},
    {field:"OLO_COMMUNICATION:OLOSTATUS", label:"OLO_COMMUNICATION:Olo status"},
    {field:"OLO_COMMUNICATION:SUSPENDED", label:"OLO_COMMUNICATION:Sospeso"}
  ];

  static readonly EP_TICKET = [
    {field:"EP_TICKET:TIPOLOGIA", label:"EP_TICKET:Tipologia"},
    {field:"EP_TICKET:OGGETTO", label:"EP_TICKET:Ogetto"},
    {field:"EP_TICKET:NOTE", label:"EP_TICKET:Note"}
  ];

  static readonly OLO_RISORSA = [
    {field: "OLO_RISORSA:IDRISORSA", label: "OLO_RISORSA:ID Risorsa"},
    {field: "OLO_RISORSA:NAMEPTE", label: "OLO_RISORSA:Nome PTE"},
    {field: "OLO_RISORSA:PORTPTE", label: "OLO_RISORSA:Porta PTE"},
    {field: "OLO_RISORSA:FIBERNUMBERPTE", label: "OLO_RISORSA:Numero Fibra PTE"},
    {field: "OLO_RISORSA:NODESPLITTERSECONDARIO", label: "OLO_RISORSA:Nodo Splitter Secondario"},
    {field: "OLO_RISORSA:NAMESPLITTERSECONDARIO", label: "OLO_RISORSA:Nome Splitter Secondario"},
    {field: "OLO_RISORSA:PORTSPLITTERSECONDARIO", label: "OLO_RISORSA:Porta Splitter Secondario"},
    {field: "OLO_RISORSA:FIBERNUMBERSPLITTERSECONDARIO", label: "OLO_RISORSA:Numero Fibra Splitter Secondario"},
    {field: "OLO_RISORSA:NODESPLITTERPRIMARIO", label: "OLO_RISORSA:Nodo Splitter Primario"},
    {field: "OLO_RISORSA:NAMESPLITTERPRIMARIO", label: "OLO_RISORSA:Nome Splitter Primario"},
    {field: "OLO_RISORSA:PORTSPLITTERPRIMARIO", label: "OLO_RISORSA:Porta Splitter Primario"},
    {field: "OLO_RISORSA:FIBERNUMBERSPLITTERPRIMARIO", label: "OLO_RISORSA:Numero Fibra Splitter Primario"},
  ]

  static readonly FTTH = [
    ...ProjectConst.BASEISSUE,
    ...ProjectConst.RIFERIMENTI,
    ...ProjectConst.CONDOMINIO,
    ...ProjectConst.CLIENTE,
    ...ProjectConst.OLO_REQUEST,
    ...ProjectConst.CONTRATO,
    ...ProjectConst.SERVIZIO_AGGIUNTIVO,
    ...ProjectConst.APPARATO,
    ...ProjectConst.APPUNTAMENTO,
    ...ProjectConst.ESPLETAMENTO_NEGATIVO,
    ...ProjectConst.ACCETTAZIONE_NEGATIVA,
    ...ProjectConst.ACQUISIZIONE_NEGATIVA,
    ...ProjectConst.ANNULLATO,
    ...ProjectConst.ESPLETAMENTO_POSITIVO,
    ...ProjectConst.OLO_SOSPENSIONE,
    ...ProjectConst.OLO_COMMUNICATION,
    ...ProjectConst.EP_TICKET,
    ...ProjectConst.OLO_RISORSA,
    // Checklist
    {field:"CHECKLIST:LABEL", label:"CHECKLIST:Label"},
    {field:"CHECKLIST:ISCHECKED", label:"CHECKLIST:Spuntato"},
  ];

  static readonly ASSETS_PROJECT = [
    ...ProjectConst.BASEISSUE,
    ...ProjectConst.ASSETS,
    ...ProjectConst.INSTALLAZIONE,
    ...ProjectConst.ORDINE_OLO
  ]



  /* static IssueTypesWithIcon: IssueTypeWithIcon[] = [
    new IssueTypeWithIcon(IssueType.BUG),
    new IssueTypeWithIcon(IssueType.STORY),
    new IssueTypeWithIcon(IssueType.TASK)
  ]; */
  /* eslint-enable @typescript-eslint/naming-convention */

  // static decodeTableColumns(key: string) {
  //   if(!key) { return ''; }
  //   return key.replace(this.splitCamel, '$1$4 $2$3$5' ).toLowerCase().replace(this.capitalize, first => first.toLocaleUpperCase());
  // }
}

export enum AuthErrorMessage {
  Username = 'Wrong Username or Email',
  Password = 'Wrong Password'
}
