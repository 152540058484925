import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_COMMUNICATION } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolCommunicationState, IIDolCommunicationStore } from "./iidOlCommunication.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolCommunicationQuery extends AbstractInterfaceDataQuery<IID_OL_COMMUNICATION> {
  constructor(protected store: IIDolCommunicationStore) {
    super(store);
  }
}
