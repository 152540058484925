import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { colorContrast } from '@ep-om/utils/styleUtills';

@Component({
  selector: 'j-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() className = 'btn-primary';
  @Input() icon: string;
  @Input() iconSize = 18;
  @Input() isWorking: boolean;
  @Input() isActive: boolean;
  @Input() disabled: boolean;
  @Input() background = 'primary';

  constructor() {}

  get _buttonStyle(){
    let style : {opacity: number; backgroundColor?: string; color?: string } = { opacity: this.disabled ? 0.6 : 1 };
    if(this.background) style = {...style, backgroundColor: this.background, color: colorContrast(this.background) };
    return style;
  }
}
