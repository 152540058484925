import { Injectable } from '@angular/core';
import { IID_GR_RESOURCES } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDGrResourcesQuery } from './iidGRResources.query';
import { IIDGrResourcesStore } from './iidGRResources.store';

@Injectable({
  providedIn: 'root'
})
export class IIDGrResourcesService extends CrudService<IID_GR_RESOURCES, IIDGrResourcesStore, IIDGrResourcesQuery> {

  constructor(
    protected store: IIDGrResourcesStore,
    public query: IIDGrResourcesQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      Topics.IID_GR_RESOURCES,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  getDescriptionObjFromInfoDescription(infoDescription: { [key: string]: string }[]): { label: string, text: string }[] {
    return infoDescription.flatMap((desc) => {
      return Object.entries(desc).map(([key, value]) => ({
        label: key.replace(/_/g, ' '),
        text: value
      }));
    });
  }

}
