import { IProjectScope } from '@common/interfaces/projectScope';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { Topics } from '@common/interfaces/topics';

export interface ProjectScopeState extends EntityState<IProjectScope, ID>, ActiveState { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.PROJECT_SCOPES, idKey: 'projectId', resettable: true })
export class ProjectScopeStore extends EntityStore<ProjectScopeState> {

  constructor() {
    super();
  }
}
