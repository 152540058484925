import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IWorkflow } from "@common/interfaces/workflow";
import { ID } from '@common/interfaces/id';
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface WorkflowState extends EntityState<IWorkflow, ID>, ActiveState { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.WORKFLOWS })
export class WorkflowStore extends EntityStore<WorkflowState> {
    constructor() {
        super([]);
    }
}
