import { Component } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FieldType, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <nz-divider></nz-divider>
    <div class="toolbar">
        <div><b>{{ props.label }}</b></div>
        <div><button nz-button nzType="primary" (click)="add()" nzShape="circle"><i nz-icon nzType="plus" nzTheme="outline"></i></button></div>
    </div>
    <div *ngFor="let field of field.fieldGroup; let i = index" class="fg">
        <formly-field [field]="field"></formly-field>
        <div ngClass="buttons-vertical">
          <button nz-button nzType="primary" (click)="remove(i)" nzShape="circle" nzDanger><i nz-icon nzType="delete"></i></button>
          <button nz-button nzType="default" nzShape="circle" (click)="movePrev(field)" [disabled]="!canMovePrev(field)"><i nz-icon nzType="arrow-up"></i></button>
          <button nz-button nzType="default" nzShape="circle" (click)="moveNext(field)" [disabled]="!canMoveNext(field)"><i nz-icon nzType="arrow-down"></i></button>
        </div>
    </div>
    <nz-divider></nz-divider>
  `
})
export class RepeatSectionTypeComponent extends FieldArrayType {

  override onPopulate(field: FieldArrayTypeConfig<(FormlyFieldProps & { [additionalProperties: string]: any; }) | undefined>) {
    super.onPopulate(field);
    //console.log("onPopulate",field);
  }

  // override add(i?:number, initialModel?:any) {
  //   super.add(i,initialModel);
  //   console.log("add",i,initialModel);
  // }

  // override remove(i: number): void {
  //   super.remove(i);
  //   console.log("remove",i);
  // }

  // get buttonsClass():string {
  //   const layout:EpFFFormLayout = this.props.attributes ? <EpFFFormLayout>this.props.attributes['layout'] : EpFFFormLayout.HORIZONTAL;
  //   const rv:string = `buttons-${ layout }`
  //   return rv;
  // }


  movePrev(field:any) {}
  moveNext(field:any) {}
  canMovePrev(field:any):boolean { return true; }
  canMoveNext(field:any):boolean { return true; }

}
