import { Injectable } from "@angular/core";
import { EntityStoreAction, runEntityStoreAction } from "@datorama/akita";
import { SocketIoService } from "@ep-om/core/services/socket-io.service";
import { delayWhen, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { Action } from "./action.model";
import { ActionQuery } from "./action.query";
import { ActionStore } from "./action.store";
import { merge, of } from 'rxjs';
import { WsACKCallback, WsACKResponse } from '@common/api/ack'
import { sha1 } from "object-hash";
import { logger } from "@ep-om/utils/logger";
import { IssueTopics, Topics } from "@common/interfaces/topics";
import { IssueStore, PendingState } from "../issue/issue.store";
import { NzNotificationService } from "ng-zorro-antd/notification";

function hasId(payload: any): payload is ({ id: string }) {
  return Object.prototype.hasOwnProperty.call(payload, 'id');
}

const debugMode = false;

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private callbacks: { [key: string]: WsACKCallback } = {};
  constructor(
    private socketService: SocketIoService,
    private _store: ActionStore,
    private _query: ActionQuery,
    private _nzNotificationService: NzNotificationService,
    private issueStore: IssueStore
  ) {
    //put action in transfer
    merge(
      this._query.inTransfer$.pipe(filter(action => !action)),
      //connected$
      this._query.queue$
    ).subscribe(() => {
      const currentStore = this._store.getValue();
      if (currentStore.inTransfer || currentStore.queue.length === 0) {
        return;
      }
      this._store.update(state => {
        const queue = state.queue.slice();
        const inTransfer = queue.shift();

        if (!inTransfer) return state;

        return { queue, inTransfer };
      })
      //}
    });

    //process the inTransfer action
    merge(
      this._query.inTransfer$.pipe(
        filter(action => !!action),
        distinctUntilChanged((x, y) => sha1(x) === sha1(y)),
      ),
      this.socketService.connected$.pipe(filter(x => x === true))
    ).pipe(
      switchMap((x) => of(x).pipe(delayWhen(() => this.socketService.connected$.pipe(filter(x => x === true)))))
    ).subscribe((value) => {
      const action = this._query.getValue().inTransfer;
      if (!action) {
        return;
      }
      if (debugMode) { logger.log("emitting action ", action) }
      this.socketService.sockets[action.topic].emit(
        `${action.type}`,
        action.payload,
        response => this.onSuccess(response, action.id)
        /* this.withTimeout(
          this.onSuccess,
          this.onTimeout,
          50000
        ));*/)
    });
  }

  setPendingState(incomingAction: Omit<Action, 'id'>, pendingState: PendingState) {
    if ((Object.values(IssueTopics).includes(incomingAction.topic as any) && incomingAction.type === 'update')) {
      this.issueStore.ui.update(incomingAction.backup['issueId'] || incomingAction.payload['id'], { pendingState });
      // runStoreAction('UI/Issues', StoreAction.Update, update => update({id: incomingAction.backup['issueId'] || incomingAction.payload['id'], pendingState }));
    }
    return;
  }

  queueAction(incomingAction: Omit<Action, 'id'>) {
    if (hasId(incomingAction.payload) && incomingAction.payload.id.startsWith('new_')) {
      return;
    }

    this.setPendingState(incomingAction, 'pending');
    let id: string;
    if (hasId(incomingAction.payload)) {
      id = `${incomingAction.topic}_${incomingAction.type}_${incomingAction.payload.id}_${Date.now()}`
    } else {
      id = `${incomingAction.topic}_${incomingAction.type}_${Date.now()}`
    }

    const action: Action = {
      id,
      topic: incomingAction.topic,
      type: incomingAction.type,
      payload: incomingAction.payload,
      backup: incomingAction.backup,
      //callback: callback && functionToJSON(callback) || null,
    };
    
    this._store.update((state) => ({
      ...state,
      queue: [...state.queue, action]
    }));
  }

  onSuccess(response: WsACKResponse<any>, actionId: string) {
    //const callback = this.callbacks[actionId];
    const inTransfer = this._store.getValue().inTransfer;
    // if(debugMode) {logger.log('WSonSuccess', response, inTransfer);}
    console.log('WSonSuccess', response, inTransfer);
    if (!inTransfer) {
      return;
    }
    const { id, topic, backup, type, payload } = inTransfer;
    this._store.update(state => ({
      ...state,
      inTransfer: null
    }));
    const callback = this.callbacks[id];
    if (response.status === 'success') {
      this.setPendingState(inTransfer, 'done');
      if (type === 'get' && response.body) {
        runEntityStoreAction(topic, EntityStoreAction.UpsertManyEntities, upsertManyEntities => upsertManyEntities(response.body))
      }
      return;
    }
    if (response.status === 'error' && response.message == 'versioning mismatch') {
      //undo && merge
    } else {
      console.log(topic, type, response);
      if (backup) {
        runEntityStoreAction(topic, EntityStoreAction.UpsertManyEntities, setEntities => setEntities([backup]));
      }
      else if (type === 'create' && hasId(payload)) {// && response.error.code !== '23505') {
        runEntityStoreAction(topic, EntityStoreAction.RemoveEntities, removeEntities => removeEntities(payload.id));
      }
      else {
        console.error('errore non gestibile', response, topic, backup, type, payload);
      }
      // store dei conflitti
    }
    if (response.status === 'error') {
      this._nzNotificationService.error('Errore', response.message);
      this.setPendingState(inTransfer, 'done');
    }
  }

  onTimeout() {
  }

  sendMessage(topic: Topics, object: string, body: any, callback?: (response: any) => void) {
    if (!callback) {
      return this.socketService.sockets[topic].emit(object, body);
    }
    return this.socketService.sockets[topic].emit(object, body, callback);
  }
}
