import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, UrlSegment, Route, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { access } from 'fs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(public authService: AuthService, public router: Router) {
  }

  

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authService.isLoggedIn()) {
      this.authService.setRedirectUlr(state.url);
      await this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    if (!this.authService.isLoggedIn()) {
      this.authService.setRedirectUlr(segments.map(s => s.path).join('/'));
      await this.router.navigate(['login']);
      return false;
    }
    return true;
  }
 
}
