import { Injectable } from '@angular/core';
import { IID_OL_SOLLECITO } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolSollecitoStore } from './iidOlSollecito.store';
import { IIDolSollecitoQuery } from './iidOlSollecito.query';
@Injectable({
  providedIn: 'root'
})
export class IIDolSollecitoService extends CrudService<IID_OL_SOLLECITO, IIDolSollecitoStore, IIDolSollecitoQuery> {
  constructor(
    protected store: IIDolSollecitoStore,
    protected query: IIDolSollecitoQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_OL_SOLLECITO,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
