import { ID } from "./id";
import { IBaseEntity } from "./base";

/****************************************
 * 
 **************** ENTITA' ***************
 * 
 ****************************************/

export interface IRole extends IBaseEntity {
  name: string;
  description?: string;
  // groups?: IGroup[];
  system?: boolean;
}

export interface IGroup extends IBaseEntity {
  name: string;
  system?: boolean;
  // default?: boolean;
  modifiable?: boolean;
}

export interface IGroupWithDefault extends IBaseEntity {
  name: string;
  default?: boolean;
  system?: boolean;
  modifiable: boolean;
}

export interface IGroupUserRelation {
  id: ID;
  userId: ID;
  groupId: ID;
}

export interface IGroupProjectRoleRelation {
  id: ID;
  groupId: ID;
  roleId: ID;
  projectId: ID;
}

export interface IPermission {
  projectId: ID;
  roles: IRole[];
  allGroups?: IGroup[];
}

/****************************************
 * 
 *********** GROUP with USERS ***********
 ***** USERS to GROUP association *******
 **** (global) GROUPS configuration *****
 * 
 ****************************************/

export interface IGroupWithUsers extends IGroup {
  userIds: string[];
};

export interface IPersistGroupsWithUsers {
  changes: IGroupWithUsersChanges[];
  newGroups: INewGroupWithUsers[];
}

export interface IGroupWithUsersChanges {
  groupId: string;
  addedUserIds: string[];
  removedUserIds: string[];
  deleted: boolean;
}

export interface INewGroupWithUsers {
  name: string;
  userIds: string[];
}

/****************************************
 * 
 *********** ROLE with GROUPS ***********
 ***** GROUPS to ROLE association *******
 ***** (PROJECT) ROLES configuration ****
 * 
 ****************************************/

export interface IRoleWithGroups extends IRole {
  groupIds: string[];
  deleted?: boolean;
}

export interface IPersistRolesWithGroups {
  projectId: string;
  newRoles: INewRoleWithGroups[];
  changes: IRoleWithGroupsChanges[];
}

export interface IRoleWithGroupsChanges {
  roleId: string;
  addedGroupIds: string[];
  removedGroupIds: string[];
  deleted: boolean;
}

export interface INewRoleWithGroups {
  name: string;
  description: string;
  groupIds: string[];
}

/****************************************
 * 
 *********** GROUP with ROLES ***********
 ***** ROLES to GROUP association *******
 ****** USERS (GROUPS) configuration ****
 * 
 ****************************************/

export interface IGroupWithRoles extends IGroup {
  roleIds: string[];
}

export interface IPersistGroupsWithRoles {
  projectId: string;
  changes: IGroupWithRolesChanges[];
}

export interface IGroupWithRolesChanges {
  groupId: string;
  addedRoleIds: string[];
  removedRoleIds: string[];
}

/****************************************
 * 
 ************** SYSTEM ROLES ************
 * 
 ****************************************/

export enum SystemRole {
  'SysAdmin' = '1',
  'Admin' = '2',
  'Manager' = '3',
  'User' = '4',
  'Service' = '5',
  'Viewer' = '6',
  'External' = '7',
  'Limited' = '8'
}

export type SystemRoleNames = keyof typeof SystemRole

export enum SystemProjectGroup {
  'ADMIN' = '1',
  'USER' = '2',
  'EVERYONE' = '3'
}

export enum SystemUser {
  'OrderManager' = '_orderManager',
  'WorkflowRules' = '_workflowRules',
}
