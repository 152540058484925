import { ID } from "./id";

export enum ImplementationMode {
    SINGLE = 1,
    MULTI = 2
}

export enum InterfaceName {
    META_INTERFACE = 0,
    CLIENTE = 1,
    CONTRATTO = 2,
    ASSETS = 3,
    RIFERIMENTI = 4,
    OLO_REQUEST = 5,
    CONDOMINIO = 6,
    SERVIZIO_AGGIUNTIVO = 7,
    APPARATO = 8,
    CHECKLIST = 9,
    RICHIESTA_PERMESSO = 10,
    RISPOSTA = 11,
    ACQUISIZIONE_NEGATIVA = 12,
    ACCETTAZIONE_NEGATIVA = 13,
    APPUNTAMENTO = 14,
    ESPLETAMENTO_NEGATIVO = 15,
    ESPLETAMENTO_POSITIVO = 16,
    ANNULLATO = 17,
    OLO_COMMUNICATION = 18,
    OLO_SOSPENSIONE = 19,
    EP_TICKET = 20,
    ORDINE_OLO = 21,
    RISPOSTA_SOLLECITO = 22,
    INSTALLAZIONE = 23,
    OLO_RISOLUZIONE = 24,
    GENERIC_SUSPENSION = 25,
    OLO_RISORSA = 26,
    OLO_RIFERIMENTI_UI = 27,
    GISFO_SUPPORT = 28,
    GR_RESOURCES = 29,
    GR_TASKS = 30,
    GR_RESOURCES_MAP = 31,
    GENERIC_KO = 32
}

export interface IIssueTypeInterface {
    id: ID;
    order: number;
    implementationMode: ImplementationMode,
    interfaceName: InterfaceName,
    issueTypeId: ID,
    createdAt: string;
    updatedAt: string;
}

export interface TabsState {
    modal: string | null, 
    route: string, 
    recentTabIndex: number
}
export interface NavigationState {
    route: string,
    openedModal: string,
    issueId?: string, 
    tabsState: TabsState[]
}

export function getInterfaceNameInterable(): InterfaceName[] {
    const result: InterfaceName[] = [];
    for (const value in InterfaceName) {
        const index = parseInt(value);
        if (!isNaN(index)) {
            result.push(index as InterfaceName)
        }
    }
    return result;
}
