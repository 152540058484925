import { Injectable } from '@angular/core';
import { IIDolRiferimentiUiStore } from './iidOlRiferimentiUi.store';
import { IID_OL_RIFERIMENTI_UI } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolRiferimentiUiQuery } from './iidOlRiferimentiUi.query'
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { WsACKCallback } from '@common/api/ack';
import { IBuildingUnits } from '@common/interfaces/gisfo';

const TOPIC = Topics.IID_OL_RIFERIMENTI_UI
@Injectable({
  providedIn: 'root'
})
export class IIDolRiferimentiUiService extends CrudService<IID_OL_RIFERIMENTI_UI, IIDolRiferimentiUiStore, IIDolRiferimentiUiQuery> {
  constructor(
    protected store: IIDolRiferimentiUiStore,
    protected query: IIDolRiferimentiUiQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy,
  ) {
    super(
      TOPIC,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  refreshFromGisfo(id: string, callback?: WsACKCallback) {
    this.actionService.sendMessage(Topics.IID_OL_RIFERIMENTI_UI, 'refresh', { id }, callback);
  }

  updateUi(dto: { id: string, payload: IBuildingUnits, updateEntity: boolean }, callback?: WsACKCallback) {
    return this.actionService.sendMessage(TOPIC, 'update_ui', dto, callback);
  }

  createUi(dto: { id: string, payload: IBuildingUnits }, callback?: WsACKCallback) {
    return this.actionService.sendMessage(TOPIC, 'create_ui', dto, callback);
  }

  deleteUi(dto: { id: string, pkBuildingUnits: string }, callback?: WsACKCallback) {
    return this.actionService.sendMessage(TOPIC, 'delete_ui', dto, callback);
  }

  associateUi(dto: { issueId: string, pkBuildingUnit: string }, callback?: WsACKCallback) {
    return this.actionService.sendMessage(TOPIC, 'associate_ui', dto, callback);
  }

}
