import { Injectable } from "@angular/core";
import { IIssue } from "@common/interfaces/issue";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";
import { InterfaceName } from "@common/interfaces/issueTypeInterface";

export type PendingState = "done" | "pending";

export type IssueUI = {
  id: ID,
  listPosition: number,
  lastUpdate?:string,
  news?: boolean,
  performedBy?:ID,
  pendingState?: PendingState
  openedInterface?: InterfaceName[];
  //lastUpdatedByMe?: boolean;
}

export interface IssuesState extends EntityState<IIssue, ID>, ActiveState { }
export interface IssuesUIState extends EntityState<IssueUI, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUES })
export class IssueStore extends EntityStore<IssuesState> {

  ui: EntityUIStore<IssuesUIState>
  constructor() {
    super();
    this.createUIStore().setInitialEntityState({ listPosition: 0, news: true, pendingState: "done", openedInterface: [] });
  }
}
