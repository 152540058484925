import { HttpClient } from '@angular/common/http';
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, filter, pairwise, repeatWhen, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthStore } from './auth.store';

import { Router } from '@angular/router';
import { AuthQuery } from './auth.query';
import { resetStores } from '@datorama/akita';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { genericRetryStrategy } from '@ep-om/utils/genericRetryStrategy';
import { logger } from '@ep-om/utils/logger';
import { WebpushNotificationService } from '@ep-om/core/services/wpn.service';
import { AppService } from '@ep-om/app.service';
import { environment } from 'src/environments/environment';
import { AuthErrorMessage } from '../config/const';
import { IUser } from '@common/interfaces/user';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _logout$ = new Subject<void>();
  public _loggingOut$ = this._logout$.asObservable();
  private _login$ = new Subject<void>();
  public loggingIn$ = this._login$.asObservable();
  private apiUrl = environment.apiUrl;
  private redirectUrl: string;
  public mainTab$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private nzns: NzNotificationService,
    private authStore: AuthStore,
    public authQuery: AuthQuery,
    private wpns: WebpushNotificationService,
    private router: Router,
    private appService: AppService,
  ) {

    this.initialize();
    
  }

  async initialize() {    
    this.appService.mainTab$.pipe(
      filter(mainTab => mainTab === true),
      switchMap(
        () => this.authQuery.refreshToken$
      ),
      filter(refresh => !!refresh),
      switchMap(refresh => {
        if (this.checkTokenExpiration(refresh)) {
          logger.log('Refresh token is expired')
          throw Error("Expired Refresh Token")
        }
        const expires = this.getExpirationFromToken(this.authStore.getValue().accessToken);
        let timeout = expires.getTime() - Date.now() - (10 * 1000);
        logger.log('token expires in', timeout / 1000, "secs");
        timeout = timeout <= 0 ? 100 : timeout;
        return of(refresh).pipe(delay(timeout));
      }),
      switchMap(refresh => {
        return this.http
          .post(`${this.apiUrl}/api/auth/refresh`, { refreshToken: refresh, clientId: this.authStore.getValue().clientId, userAgent: window.navigator.userAgent })
          .pipe(
            retryWhen(genericRetryStrategy({
              maxRetryAttempts: Infinity,
              scalingDuration: 3000,
              excludedStatusCodes: [401],
            })),
          )
      }),
      takeUntil(this._logout$),
      repeatWhen(() => this._login$),
    ).subscribe({
      next: (response: any) => {
        this.authStore.update(state => ({
          ...state,
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          userAgent: window.navigator.userAgent
        }))
        logger.log('new token arrived', response)
      },
      error: async (err) => {
        this.nzns.create(
          'error',
          'Autenticazione fallita, è necessario ripetere il login.',
          ''
        );
        await this.logout();
      }
    });

    this.authQuery.loggedIn$.pipe(
      pairwise(),
      tap(async ([beforeLoggedIn, afterLoggedIn]) => {
        if (beforeLoggedIn === true && afterLoggedIn === false) {
          await this.router.navigate(['/login'])
        }
      })
    ).subscribe();
  }

  isLoggedIn() {
    return !!this.authQuery.getValue().accessToken;
  }

  setRedirectUlr(url: string) {
    this.redirectUrl = url;
  }

  setUserIdFromToken(token: string) {
    const payload = jwt_decode(token) as any;
    this.authStore.update({ userId: payload.userId })
  }

  getExpirationFromToken(token: string): Date {
    const payload = jwt_decode(token) as JwtPayload;
    if (payload.exp)
      return new Date(payload.exp * 1000);
    else
      return new Date(0)
  }

  checkTokenExpiration(token: string, date = new Date()): boolean {
    const expirationDate = this.getExpirationFromToken(token);

    if (expirationDate.valueOf() < date.valueOf())
      return true

    return false;
  }

  async forgotPassword(email: string) {

    try {
      await this.http.post(`${this.apiUrl}/api/auth/forgotpassword`, { email }).toPromise();
      this.nzns.create('success', 'Operazione effettuata.', 'Controllare la posta ...');
    }
    catch (err) {
      this.nzns.create('error', 'Operazione non riuscita.', err.message);
    }

  }

  login({ username = '', password = '' }) {
    this.authStore.setLoading(true);
    this.http
      .post(`${this.apiUrl}/api/auth/login`, { username, password, ...this.authStore.getValue() })
      //.pipe(this.httpPipe)
      .subscribe({
        next: (response: any) => {
          logger.log('Auth Response', response)          
          this.authStore.update((state) => ({
            ...state,
            accessToken: response.accessToken,
            refreshToken: response.refreshToken
          }));         
          this.setUserIdFromToken(this.authStore.getValue().refreshToken);
        },
        complete: async () => {          
          this.nzns.create(
            'success',
            'Login effettuato.',
            ''
          );
          this._login$.next();
          if (this.redirectUrl) {
            console.log('redirecting to url');
            await this.router.navigateByUrl('/' + this.redirectUrl);
            this.redirectUrl = null;
            return;
          }
          await this.router.navigate(['/']);
        },
        error: (body) => {
          logger.log(body)
          if (body.status === 503) {
            this.appService.setMaintenance(true);
            this.router.navigate(['maintenance']);
          }
          this.checkAuthError(body.error.message, username)          
        }
      });
  }

  checkAuthError(error: string, username?: string){
    switch (error) {
      case AuthErrorMessage.Username: {
        const isEmail = username.includes('@')
        this.nzns.create(
          'error',
          `${isEmail? 'Email' : 'Username'} non esistente.`,
          ''
        );
        break;
      }
      case AuthErrorMessage.Password: {
        this.nzns.create(
          'error',
          'Password sbagliata.',
          ''
        );
        break;
      }
      default: {
        this.nzns.create(
          'error',
          'Login fallito.',
          ''
        );
      }
    }
  }

  async register({ email = '', password = '', name = '' }) {
    try {
      await this.http.post(`${this.apiUrl}/api/auth/register`, { email, name, password }).toPromise();
      this.nzns.create('success', 'Operazione effettuata.', 'Controllare la posta ...');
      this.router.navigate(['login']);
    } catch (e) {
      const errorMessage = e.error?.message?.length > 0 ? e.error.message.map(m => ` • ${m}`).join('\n') : e.message;
      this.nzns.create('error', 'Operazione non riuscita.', errorMessage);
    }
    
  }


  async logout() {
    const clientId = this.authStore.getValue().clientId;

    try {
      await this.wpns.clean()
    } catch (e) { }

    this.http
      .post(`${this.apiUrl}/api/auth/logout`,
        { clientId },
        { headers: { authorization: `Bearer ${this.authStore.getValue().accessToken}` } }
      )
      //.pipe(this.httpPipe)
      .subscribe();
      localStorage.clear();
      resetStores()
      this.authStore.update(state => ({ accessToken: null, refreshToken: null, clientId }));
      this._logout$.next();
  }

  updateUser(user: IUser) {
    this.authStore.update(state => ({
      ...state,
      user
    }));
  }

}


